import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastIds } from '~constants/toast-ids';
import { defaultUserSettings } from '~utils/children';
import { RequestStatus } from '~utils/request-state';
import { showApiErrorToast, showMessageToast } from '~utils/toast-utils';
import { setSubscriberId } from '~utils/tokens';
import { IUserSettings } from '../parent-child/types';
import { fetchPickupsAsync } from '../pickups';
import {
    getAccountInfoRequest,
    getBillingValidationStatusRequest,
    getPickupPlanRequest,
    markEmailHealthcheckAsIgnoredRequest,
    removeEmailHealthcheckRequest,
    updateVacationHoldRequest,
} from './api';
import {
    IAccountInfo,
    IEmailHealthcheck,
    ISubscriberInfo,
    IUserPickupPlan,
    IUserPickupPlanState,
} from './types';

interface AccountInfoState {
    subscriber: ISubscriberInfo;
    customerHold: IAccountInfo['customerHold'];
    vacationHold: IAccountInfo['vacationHold'];
    vacationHoldLoading: IAccountInfo['vacationHoldLoading'];
    isCorrectBillingInfo: IAccountInfo['isCorrectBillingInfo'];
    hasEnteredPromoCode: IAccountInfo['hasEnteredPromoCode'];
    hasDeclinedInvoices: IAccountInfo['hasDeclinedInvoices'];
    hasFailedPreAuths: IAccountInfo['hasFailedPreAuths'];
    enableDriverTips: IAccountInfo['enableDriverTips'];
    emailHealthcheck: IEmailHealthcheck[];
    userPickupPlanState: IUserPickupPlanState;
    signupQuery: IAccountInfo['signupQuery'];
    isParent: boolean;
    isAccountInfoLoading: boolean;
    settings: IUserSettings;
}

const initialState: AccountInfoState = {
    userPickupPlanState: {
        data: {} as IUserPickupPlan,
        status: RequestStatus.IDLE,
    } as IUserPickupPlanState,

    subscriber: {} as ISubscriberInfo,
    customerHold: false,
    vacationHold: false,
    vacationHoldLoading: false,
    isCorrectBillingInfo: true,
    hasEnteredPromoCode: false,
    hasDeclinedInvoices: false,
    hasFailedPreAuths: false,
    emailHealthcheck: [],
    signupQuery: false,
    isParent: false,
    isAccountInfoLoading: false,
    settings: defaultUserSettings,
};

export const markEmailHealthcheckAsIgnoredAsync = createAsyncThunk(
    'accountInfo/markEmailHealthcheckAsIgnored',
    async () => {
        await markEmailHealthcheckAsIgnoredRequest();
    },
);

export const removeEmailHealthcheckAsync = createAsyncThunk(
    'accountInfo/removeEmailHealthcheck',
    async () => {
        await removeEmailHealthcheckRequest();
    },
);

export const getAccountInfoAsync = createAsyncThunk('accountInfo/getAccountInfo', async () => {
    const { data } = await getAccountInfoRequest();
    return data;
});

export const updateVacationHoldAsync = createAsyncThunk(
    'accountInfo/updateVacationHoldAsync',
    async (vacationHold: boolean, { dispatch }) => {
        try {
            await updateVacationHoldRequest(vacationHold);

            // TODO: change to required titles
            if (vacationHold) {
                showMessageToast(
                    'Your account has been placed on hold!',
                    ToastIds.ACCOUNT_PLACED_ON_HOLD,
                );
            } else {
                showMessageToast(
                    'Hold has been removed from your account!',
                    ToastIds.ACCOUNT_REMOVED_FROM_HOLD,
                );
            }
            dispatch(fetchPickupsAsync());

            // TODO: replace with return from api if implement
            return vacationHold;
        } catch (err) {
            showApiErrorToast(err, ToastIds.PLACE_ON_HOLD_REJECTED);
            throw err;
        }
    },
);

export const getBillingValidationStatusAsync = createAsyncThunk(
    'accountInfo/getBillingValidationStatusAsync',
    async () => {
        const { data } = await getBillingValidationStatusRequest();

        return data;
    },
);

export const fetchUserPickupPlanAsync = createAsyncThunk(
    'accountInfo/fetchUserPickupPlanAsync',
    async () => {
        const { data } = await getPickupPlanRequest();
        return data;
    },
);

function setUserAccountInfo(state: AccountInfoState, action: PayloadAction<IAccountInfo>) {
    state.isAccountInfoLoading = false;
    const { payload: accountInfo } = action;
    state.subscriber = {
        subscriberId: accountInfo.subscriberId,
        subscriberEmail: accountInfo.subscriberEmail,
        subscriberPhone: accountInfo.subscriberPhone,
    };
    state.customerHold = accountInfo.customerHold;
    state.vacationHold = accountInfo.vacationHold;
    state.vacationHoldLoading = accountInfo.vacationHoldLoading;
    state.isCorrectBillingInfo = accountInfo.isCorrectBillingInfo;
    state.hasEnteredPromoCode = accountInfo.hasEnteredPromoCode;
    state.hasDeclinedInvoices = accountInfo.hasDeclinedInvoices;
    state.hasFailedPreAuths = accountInfo.hasFailedPreAuths;
    state.emailHealthcheck = accountInfo.emailHealthcheck;
    state.isParent = accountInfo.isParent;
    state.settings = accountInfo.settings || defaultUserSettings;
    state.enableDriverTips = accountInfo.enableDriverTips;
    setSubscriberId(accountInfo.subscriberId);
}

const accountInfoSlice = createSlice({
    name: 'accountInfo',
    initialState,
    reducers: {
        setAccountInfo: setUserAccountInfo,
        setSignupQuery: (state, action) => {
            state.signupQuery = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccountInfoAsync.pending, (state) => {
                state.isAccountInfoLoading = true;
            })
            .addCase(getAccountInfoAsync.fulfilled, (state, action) => {
                setUserAccountInfo(state, action);
            })
            .addCase(getAccountInfoAsync.rejected, (state) => {
                state.isAccountInfoLoading = false;
            })

            .addCase(updateVacationHoldAsync.pending, (state) => {
                state.vacationHoldLoading = true;
            })
            .addCase(updateVacationHoldAsync.fulfilled, (state, action) => {
                state.vacationHold = action.payload;
                state.vacationHoldLoading = false;
            })
            .addCase(updateVacationHoldAsync.rejected, (state) => {
                state.vacationHoldLoading = false;
            })

            .addCase(getBillingValidationStatusAsync.fulfilled, (state, action) => {
                state.isCorrectBillingInfo = action.payload;
            })

            .addCase(markEmailHealthcheckAsIgnoredAsync.fulfilled, (state) => {
                state.emailHealthcheck = [];
            })
            .addCase(markEmailHealthcheckAsIgnoredAsync.rejected, (state) => {
                state.emailHealthcheck = [];
            })

            .addCase(removeEmailHealthcheckAsync.fulfilled, (state) => {
                state.emailHealthcheck = [];
            })
            .addCase(removeEmailHealthcheckAsync.rejected, (state) => {
                state.emailHealthcheck = [];
            })

            .addCase(fetchUserPickupPlanAsync.pending, (state) => {
                state.userPickupPlanState.status = RequestStatus.LOADING;
            })
            .addCase(fetchUserPickupPlanAsync.fulfilled, (state, { payload }) => {
                state.userPickupPlanState.status = RequestStatus.SUCCEEDED;
                state.userPickupPlanState.data = payload;
            })
            .addCase(fetchUserPickupPlanAsync.rejected, (state) => {
                state.userPickupPlanState.status = RequestStatus.FAILED;
            });
    },
});

export const { setAccountInfo, setSignupQuery } = accountInfoSlice.actions;

export default accountInfoSlice.reducer;
