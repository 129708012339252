import axios, { Axios, AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { ToastIds } from '~constants/toast-ids';
import { setAccountInfo } from '~store/modules/account-info';
import { logout } from '~store/modules/auth';
import { resetSignup } from '~store/modules/signup';
import {
    isInternalServerError,
    isNoInternetError,
    isServiceUnavailableError,
    isUnauthorizedError,
} from './errors';
import logger from './logger';
import storeHelper from './store-helper';
import { defaultErrorMessage, serviceUnavailableMessage, showErrorToast } from './toast-utils';
import {
    getAccessToken,
    getRefreshToken,
    getSignupId,
    getSignupToken,
    getSubscriberId,
    getUserId,
    parseAndSaveUserInfo,
    removeSignupId,
    removeSignupToken,
} from './tokens';

export interface IRequestState {
    isSucceed: boolean;
    isLoading: boolean;
    isError: boolean;
}

export interface ITokenRequest {
    token: string;
    onInvalidToken: () => void;
}

export type LoginResponse = {
    user: {
        PK_usersID: number;
        FK_PK_subscriberID: number;
        FK_PK_signupID: number;
        firstname: string;
        customerHold: boolean;
        vacationHold: boolean;
        isCorrectBillingInfo: boolean;
        hasDeclinedInvoices: boolean;
        hasFailedPreAuths: boolean;
    };
    tokens: {
        accessToken: string;
        refreshToken: string;
    };
    subscriber: {
        emailHello: string;
        subscriberPhone: string;
    };
};

export type RequestFailed = {
    statusCode: number;
    message: string;
    error: string;
};

export enum RequestTypes {
    GET = 'get',
    POST = 'post',
    PATCH = 'patch',
}

export const clearOutdatedSignupData = () => {
    removeSignupToken();
    removeSignupId();
    storeHelper.dispatch(resetSignup());
};

const refreshRequest = (token: string) =>
    axios.post('/auth/refresh', null, {
        baseURL: window.hnApi,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

const retryRequestWithNewToken = (token: string, config: AxiosRequestConfig) =>
    axios.request({
        ...config,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const refresh = async (config?: AxiosRequestConfig) => {
    const oldRefreshToken = getRefreshToken();

    if (oldRefreshToken) {
        try {
            const { data } = await refreshRequest(oldRefreshToken);

            const {
                tokens: { accessToken },
            } = data;

            const userInfo = parseAndSaveUserInfo(data);
            storeHelper.dispatch(setAccountInfo(userInfo));

            if (config) {
                const response = await retryRequestWithNewToken(accessToken, config);
                return response;
            }
            return true;
        } catch (refreshError: any) {
            if (refreshError?.response?.status === 401) {
                storeHelper.dispatch(logout());
            } else {
                throw refreshError;
            }
        }
    } else {
        const oldSignupToken = getSignupToken();
        if (oldSignupToken) {
            clearOutdatedSignupData();
        }
        storeHelper.dispatch(logout());
    }
};

export const requestInterceptor = (config: AxiosRequestConfig) => {
    const accessToken = getAccessToken();
    const signupToken = getSignupToken();
    if (config.headers && !config.headers.Authorization) {
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        } else if (signupToken) {
            config.headers.Authorization = `Bearer ${signupToken}`;
        }
    }
    return config;
};

const internalServerErrorAPIExceptions = [
    'reprocessDeclinedInvoices',
    'reprocessFailedPreAuths',
    'reprocessSettleUpDeclinedInvoices',
];

const apiErrorInExceptions = (error: AxiosError, exceptionsList: string[], method: RequestTypes) =>
    error.config.method === method &&
    exceptionsList.some((item) => error.request.responseURL.includes(item));

export const responseErrorInterceptor = async (error: AxiosError) => {
    if (isNoInternetError(error)) {
        showErrorToast(
            'Something went wrong. Please check your internet connection or reload the page.',
            ToastIds.NO_INTERNET_CONNECTION,
        );
    }

    if (isUnauthorizedError(error)) {
        const refreshResponse = await refresh(error.config);
        if (refreshResponse) {
            return refreshResponse;
        }
    }

    if (
        isInternalServerError(error) &&
        !apiErrorInExceptions(error, internalServerErrorAPIExceptions, RequestTypes.POST)
    ) {
        showErrorToast(defaultErrorMessage, ToastIds.API_INTERNAL_ERROR);
        logger.error(
            error.response
                ? JSON.stringify(error.response?.data)
                : 'Internal server error detected',
        );
    }

    if (isServiceUnavailableError(error)) {
        showErrorToast(serviceUnavailableMessage, ToastIds.SERVICE_UNAVAILABLE);
    }

    throw error;
};

const axiosClient = axios.create({
    baseURL: window.hnApi,
});

axiosClient.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
axiosClient.interceptors.response.use((response) => response, responseErrorInterceptor);

interface IClient {
    get: Axios['get'];
    post: Axios['post'];
    patch: Axios['patch'];
    delete: Axios['delete'];
}

interface IApiClient extends IClient {
    user: IClient;
    subscriber: IClient;
    subscriberUser: IClient;
    signup: IClient;
}

const createApiClient = (client: AxiosInstance) => {
    const getUserUrl = (url: string) => `/users/${getUserId()}${url}`;
    const getSubscriberUrl = (url: string) => `/subscribers/${getSubscriberId()}${url}`;
    const getSignupUrl = (url: string) => `/signup/${getSignupId()}${url}`;

    const subscriberUser: IClient = {
        get: (url, ...params) => client.get(getSubscriberUrl(getUserUrl(url)), ...params),
        post: (url, ...params) => client.post(getSubscriberUrl(getUserUrl(url)), ...params),
        patch: (url, ...params) => client.patch(getSubscriberUrl(getUserUrl(url)), ...params),
        delete: (url, ...params) => client.delete(getSubscriberUrl(getUserUrl(url)), ...params),
    };

    const user: IClient = {
        get: (url, ...params) => client.get(getUserUrl(url), ...params),
        post: (url, ...params) => client.post(getUserUrl(url), ...params),
        patch: (url, ...params) => client.patch(getUserUrl(url), ...params),
        delete: (url, ...params) => client.delete(getUserUrl(url), ...params),
    };

    const subscriber: IClient = {
        get: (url, ...params) => client.get(getSubscriberUrl(url), ...params),
        post: (url, ...params) => client.post(getSubscriberUrl(url), ...params),
        patch: (url, ...params) => client.patch(getSubscriberUrl(url), ...params),
        delete: (url, ...params) => client.delete(getSubscriberUrl(url), ...params),
    };

    const signup: IClient = {
        get: (url, ...params) => client.get(getSignupUrl(url), ...params),
        post: (url, ...params) => client.post(getSignupUrl(url), ...params),
        patch: (url, ...params) => client.patch(getSignupUrl(url), ...params),
        delete: (url, ...params) => client.delete(getSignupUrl(url), ...params),
    };

    const apiClient: IApiClient = {
        get: (...params) => client.get(...params),
        post: (...params) => client.post(...params),
        patch: (...params) => client.patch(...params),
        delete: (...params) => client.delete(...params),
        user,
    };

    apiClient.user = user;
    apiClient.subscriber = subscriber;
    apiClient.subscriberUser = subscriberUser;
    apiClient.signup = signup;

    return apiClient;
};

const client = createApiClient(axiosClient);

export default client;
