import { Stack } from '@mui/material';
import { HELLO_EMAIL } from '~constants/emails';
import { HN_PHONE } from '~constants/phone';
import Link from '~shared/link';
import Typography from '~shared/typography';
import { formatPhoneNumber } from '~utils/formatters';
import style from './footer-contacts.style';

const FooterContacts = () => {
    const phone = formatPhoneNumber(HN_PHONE);

    return (
        <>
            <Stack sx={style.section} data-testid='footerContacts'>
                <Typography variant='subtitle1' color='action'>
                    Company
                </Typography>
                <Link id='aboutUsLink' href='https://www.happynest.com/about-us/' sx={style.link1}>
                    About Us
                </Link>
                <Link
                    id='locationsLink'
                    href='https://www.happynest.com/locations'
                    sx={style.link2}
                >
                    Locations
                </Link>
            </Stack>
            <Stack sx={style.section}>
                <Typography variant='subtitle1' color='action'>
                    How It Works
                </Typography>
                <Link id='faqLink' href='https://www.happynest.com/faqs/' sx={style.link1}>
                    FAQs
                </Link>
                <Link id='blogLink' href='https://www.happynest.com/blog' sx={style.link2}>
                    Blog
                </Link>
            </Stack>
            <Stack sx={style.section}>
                <Typography variant='subtitle1' color='action'>
                    Contact Us
                </Typography>
                <Link id='phoneToLink' href={`tel:${phone}`} sx={style.link1}>
                    {phone}
                </Link>
                <Link id='emailToLink' href={`mailto:${HELLO_EMAIL}`} sx={style.link2}>
                    {HELLO_EMAIL}
                </Link>
            </Stack>
        </>
    );
};

export default FooterContacts;
