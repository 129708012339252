import { IDateInfo } from '~shared/dashboard/schedule-calendar/utils/calendar-date';

export enum PickupPromptStatus {
    NotExist = 0,
    Active = 1,
    Expired = 2,
    PickupAlreadyCreated = 3,
}

export interface PickupPromptPayload {
    linkType: number;
    payload: {
        pickupPromptsId: number;
        requestSource: number;
        userId: number;
        dateRequested: string;
        pickupDayAsString: string;
    };
}

export enum UpdatePickupPlan {
    None = 0,
    Weekly = 1,
    BiWeekly = 3,
    ByRequest = 2,
}

export const getPlanTypeAsString = (planType: UpdatePickupPlan): string => {
    switch (planType) {
        case UpdatePickupPlan.Weekly:
            return 'Weekly';
        case UpdatePickupPlan.BiWeekly:
            return 'Bi-Weekly';
        case UpdatePickupPlan.ByRequest:
            return 'By-Request';
        default:
            return '';
    }
};

export const getPickupScheduledSuccessText = (
    userPlanType?: UpdatePickupPlan,
    dateInfo?: IDateInfo,
) => {
    if (userPlanType && dateInfo) {
        const { day, date, month } = dateInfo;
        const planTypeAsString = getPlanTypeAsString(Number(userPlanType));

        return Number(userPlanType) === UpdatePickupPlan.Weekly ||
            Number(userPlanType) === UpdatePickupPlan.BiWeekly
            ? `${planTypeAsString} ${day} pickups starting ${month} ${date} successfully scheduled`
            : `Pickup for ${day}, ${month} ${date} successfully scheduled`;
    }

    return 'Pickup successfully scheduled';
};

export const getPickupCanceledSuccessText = (
    userPlanType?: UpdatePickupPlan,
    dateInfo?: IDateInfo,
) => {
    if (userPlanType && dateInfo) {
        const { day, date, month } = dateInfo;

        return userPlanType === UpdatePickupPlan.Weekly ||
            userPlanType === UpdatePickupPlan.BiWeekly
            ? `${day} pickups successfully canceled`
            : `Pickup for ${day}, ${month} ${date} successfully canceled`;
    }

    return 'Pickup successfully canceled';
};
