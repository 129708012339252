import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUserRates } from '~constants/rates';
import { getSubscriberRatesRequest, getUserRatesRequest } from './api';

const initialState = {
    userRates: {
        minimumPickupFee: 0,
        rateRequest: 0,
        rateWeekly: 0,
        serviceFeeByRequest: 0,
        serviceFeeWeekly: 0,
        calculationType: 1,
    } as IUserRates,
};

export const fetchUserRates = createAsyncThunk('rates/userRates', async () => {
    const { data } = await getUserRatesRequest();
    return data;
});

export const fetchSubscriberRates = createAsyncThunk(
    'rates/subscriberRates',
    async (zipCode: string) => {
        const { data } = await getSubscriberRatesRequest(zipCode);
        return data;
    },
);

const ratesSlice = createSlice({
    name: 'rates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserRates.fulfilled, (state, action) => {
            state.userRates = action.payload;
        });
        builder.addCase(fetchSubscriberRates.fulfilled, (state, action) => {
            state.userRates = action.payload;
        });
    },
});

export default ratesSlice.reducer;
