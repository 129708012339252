import useAppSelector from '~hooks/use-app-selector';
import Typography from '~shared/typography';
import { getIsAccountInfoSuccess, isParentAccount } from '~store/modules/account-info/selectors';
import { getCreditAmount, selectUserDetails } from '~store/modules/personal-info/selectors';
import { formatDollar, getFullName } from '~utils/formatters';
import style from './remaining-credits.style';

const RemainingCredits = () => {
    const creditAmount = formatDollar(useAppSelector(getCreditAmount) || 0);

    const isParent = useAppSelector(isParentAccount);
    const userDetails = useAppSelector(selectUserDetails);
    const isAccountInfoSuccess = useAppSelector(getIsAccountInfoSuccess);

    const content = isParent
        ? userDetails.companyName || getFullName(userDetails)
        : `Remaining Credits: ${creditAmount}`;

    return (
        <Typography
            data-testid='remaining-credits'
            id='credits'
            color='action'
            variant='subtitle1'
            sx={style.root}
        >
            {isAccountInfoSuccess && content}
        </Typography>
    );
};

export default RemainingCredits;
