import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DialogTypes from '~constants/dialog-types';

type DialogState = {
    openedDialog: DialogTypes | null;
    openedAdditionalDialog: DialogTypes | null;
    isAdditionalDialogOpened: boolean;
};

const initialState: DialogState = {
    openedDialog: null,
    openedAdditionalDialog: null,
    isAdditionalDialogOpened: false,
};

const dialogSlice = createSlice({
    initialState,
    name: 'dialog',
    reducers: {
        openDialog: (state, action: PayloadAction<DialogTypes>) => {
            if (state.openedDialog) {
                state.openedAdditionalDialog = action.payload;
                state.isAdditionalDialogOpened = true;
            } else {
                state.openedDialog = action.payload;
            }
        },
        closeDialog: (state) => {
            if (state.isAdditionalDialogOpened) {
                state.openedAdditionalDialog = null;
                state.isAdditionalDialogOpened = false;
            } else {
                state.openedDialog = null;
            }
        },
        openAdditionalDialog: (state, action: PayloadAction<DialogTypes>) => {
            state.openedAdditionalDialog = action.payload;
            state.isAdditionalDialogOpened = true;
        },
        closeAdditionalDialog: (state) => {
            state.openedAdditionalDialog = null;
            state.isAdditionalDialogOpened = false;
        },
    },
});

export const { openDialog, closeDialog, openAdditionalDialog, closeAdditionalDialog } =
    dialogSlice.actions;

export default dialogSlice.reducer;
