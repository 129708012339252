import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { RequestFailed } from '~utils/hn-api';
import { RequestStatus } from '~utils/request-state';
import { getUserDetailsAsync } from '../personal-info';
import {
    applyPromoCodeRequest,
    fetchActivePromoCodes,
    fetchCreditsHistory,
    validatePromoCodeRequest,
} from './api';
import {
    IActivePromoCode,
    IActivePromoCodesState,
    IApplyPromoCodeResultState,
    ICreditHistory,
    ICreditsHistoryState,
    IPromoCodeValidation,
    IPromoCodeValidationResultState,
} from './types';

const initialState = {
    applyPromoCode: {
        status: RequestStatus.IDLE,
    } as IApplyPromoCodeResultState,

    activePromoCodes: {
        data: [] as IActivePromoCode[],
        status: RequestStatus.IDLE,
    } as IActivePromoCodesState,

    creditsHistory: {
        data: [] as ICreditHistory[],
        status: RequestStatus.IDLE,
    } as ICreditsHistoryState,

    promoCodeValidation: {
        data: {} as IPromoCodeValidation,
        status: RequestStatus.IDLE,
    } as IPromoCodeValidationResultState,
};

export const fetchActivePromoCodesAsync = createAsyncThunk(
    'promoCodes/fetchActivePromoCodesAsync',
    async () => {
        const { data } = await fetchActivePromoCodes();
        return data;
    },
);

export const fetchCreditsHistoryAsync = createAsyncThunk(
    'promoCodes/fetchCreditsHistoryAsync',
    async () => {
        const { data } = await fetchCreditsHistory();
        return data;
    },
);

export const applyPromoCodeAsync = createAsyncThunk(
    'promoCodes/applyPromoCodeAsync',
    async (promoCode: string, { rejectWithValue, dispatch }) => {
        try {
            await applyPromoCodeRequest(promoCode);
            dispatch(fetchActivePromoCodesAsync());
            dispatch(getUserDetailsAsync());
        } catch (error: any) {
            const axiosError: AxiosError<RequestFailed, any> = error;

            if (!axiosError.response) {
                throw error;
            }

            return rejectWithValue(axiosError.response.data);
        }
    },
);

export const validatePromoCodeAsync = createAsyncThunk(
    'promoCodes/validatePromoCodeAsync',
    async (promoCode: string, { rejectWithValue, dispatch }) => {
        try {
            dispatch(resetPromoCodeValidation());
            const { data } = await validatePromoCodeRequest(promoCode);
            return data;
        } catch (error: any) {
            const axiosError: AxiosError<RequestFailed, any> = error;

            if (!axiosError.response) {
                throw error;
            }

            return rejectWithValue(axiosError.response.data);
        }
    },
);

const promoCodesSlice = createSlice({
    name: 'promoCodes',
    initialState,
    reducers: {
        resetPromoCodeValidation: (state) => {
            state.promoCodeValidation = {
                data: {} as IPromoCodeValidation,
                status: RequestStatus.IDLE,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(applyPromoCodeAsync.pending, (state) => {
            state.applyPromoCode.status = RequestStatus.LOADING;
        });
        builder.addCase(applyPromoCodeAsync.fulfilled, (state) => {
            state.applyPromoCode.status = RequestStatus.SUCCEEDED;
        });
        builder.addCase(applyPromoCodeAsync.rejected, (state) => {
            state.applyPromoCode.status = RequestStatus.FAILED;
        });

        builder.addCase(fetchActivePromoCodesAsync.pending, (state) => {
            state.activePromoCodes.status = RequestStatus.LOADING;
        });
        builder.addCase(fetchActivePromoCodesAsync.fulfilled, (state, action) => {
            state.activePromoCodes.status = RequestStatus.SUCCEEDED;
            state.activePromoCodes.data = action.payload;
        });
        builder.addCase(fetchActivePromoCodesAsync.rejected, (state) => {
            state.activePromoCodes.status = RequestStatus.FAILED;
        });

        builder.addCase(fetchCreditsHistoryAsync.pending, (state) => {
            state.creditsHistory.status = RequestStatus.LOADING;
        });
        builder.addCase(fetchCreditsHistoryAsync.fulfilled, (state, action) => {
            state.creditsHistory.status = RequestStatus.SUCCEEDED;
            state.creditsHistory.data = action.payload;
        });
        builder.addCase(fetchCreditsHistoryAsync.rejected, (state) => {
            state.creditsHistory.status = RequestStatus.FAILED;
        });

        builder.addCase(validatePromoCodeAsync.pending, (state) => {
            state.promoCodeValidation.status = RequestStatus.LOADING;
        });
        builder.addCase(validatePromoCodeAsync.fulfilled, (state, { payload }) => {
            state.promoCodeValidation.status = RequestStatus.SUCCEEDED;
            state.promoCodeValidation.data = payload;
        });
        builder.addCase(validatePromoCodeAsync.rejected, (state, action) => {
            if (action.payload !== undefined) {
                state.promoCodeValidation.status = RequestStatus.FAILED;
            } else {
                state.promoCodeValidation.status = RequestStatus.IDLE;
            }
        });
    },
});

export const { resetPromoCodeValidation } = promoCodesSlice.actions;

export default promoCodesSlice.reducer;
