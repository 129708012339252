const style: Style = {
    box: {
        lineHeight: 0,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',

        '> *': {
            display: 'inline',
        },
    },
};

export default style;
