import { CancelType } from '~constants/cancel-type';
import { PickupPromptStatus } from '~constants/schedule-pickup';
import { IRequestState } from '~utils/hn-api';
import { OrderType } from '~utils/pickup';
import { IRequestState as INewRequestState } from '~utils/request-state';

export enum CancellationPickupStatus {
    AvailableToCancel = 1,
    DriverOnTheWay = 2,
    Canceled = 3,
    TicketClosed = 4,
    NotFound = 5,
    NoRemainingStops = 6,
}

export enum NextPickupProgress {
    NoPickup = 0,
    EtaCanceledOnTheWay = 1,
    PickedUp = 2,
    InTheWash = 3,
    LaundrysDone = 4,
    Folded = 5,
    DroppedOff = 6,
    CanceledNextPickup = 7,
    PickedUpMissedDropOff = 8,
    DroppedOffMissedPickup = 9,
    MissedPickup = 10,
    FoldedEleven = 11,
    Tagged = 12,
    MissedDropOff = 14,
    MissedDropOffMissedPickup = 15,
    AtHappyNest = 17,
    PickedUpDroppedOff = 18,
    ETA = 20,
    Canceled = 21,
    OnTheWay = 22,
    NextPickup = 23,
    TodayPickup = 24,
}

export enum PickupDateType {
    NotAvailable = 1,
    Available = 2,
    Scheduled = 3,
    OffScheduled = 4,
    RootBlock = 5,
    Canceled = 6,
    ScheduledByRequest = 7,
    SameDayCancel = 8,
}

export interface IPickup {
    routePickupDaysID: number;
    date: string;
    formattedDate: string;
    pickupDay: number;
    pickupDayAsString: string;
    dropOffDay: number;
    dateType: PickupDateType;
    isSelected: boolean;
    isRequested: boolean;
    weeklyUpChargePrice: number;
    byRequestUpChargePrice: number;
    cancelType: CancelType;
    isCustomPickup?: boolean;
    isRewash?: boolean;
}

export interface INextPickup {
    type: NextPickupProgress;
    dateTime: string;
    date: string;
    formattedDate: string;
    status: string;
    showCancelPickupLink: boolean;
    chargedFee: boolean;
    hasActiveManifest: boolean;
    cancelType: CancelType;
    time: string;
    orderType: OrderType;
    showResetStatusLink: boolean;
    arrivalWindowMin?: string;
    arrivalWindowMax?: string;
    remainingStops?: number;
    photoUrl?: string;
    isRewash?: boolean;
}

export interface ILastPickup {
    scheduledPickupDate?: string;
    missedPickup?: number;
}

export interface IPickupState extends INewRequestState {
    data: IPickup[];
}

export interface INextPickupProgressState extends INewRequestState {
    data: INextPickup;
}

export interface ICancellationPickupInfo {
    status: CancellationPickupStatus;
    pickupDate: Date;
    subscriberPhone: string;
    isRewash?: boolean;
}

export interface ICancellationPickupInfoState extends IRequestState {
    data: ICancellationPickupInfo;
}

export interface ISettleUpInfo {
    firstName: string;
    subscriberPhone: string;
    subscriberEmail: string;
    pickupDate: string;
    pickupDayAsString: string;
    pickupStatus: PickupPromptStatus;
}

export interface ISettleUpInfoState extends IRequestState {
    data: ISettleUpInfo;
}

export interface ISettleUpStatusRequest {
    pickupDate: string;
    pickupDayAsString: string;
}
