import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import { mapUserDetails } from '~store/modules/personal-info/types';
import { defaultUserSettings } from '~utils/children';
import { RequestStatus } from '~utils/request-state';
import {
    IChildLaundryPreferencesState,
    IChildRateState,
    IChildSettingsState,
    IChildState,
    ISettings,
    IUserSettings,
} from '../types';
import {
    getChildBillingValidationStatus,
    getChildLaundryPreferences,
    getChildRates,
    getChildren,
    getChildrenSettings,
    setChildrenSettings,
    setChildSettings,
} from './api';

export const selectChildId = (child: IChildState) => child.user.userId;

export const selectChildRateId = (childRate: IChildRateState) => childRate.userId;

export const selectChildSettingsId = (settings: IChildSettingsState) =>
    settings.settings.FK_PK_usersID;

export const selectLaundryPreferencesId = (lp: IChildLaundryPreferencesState) => lp.userId;

export const childrenAdapter = createEntityAdapter<IChildState>({
    selectId: selectChildId,
});

export const childrenRatesAdapter = createEntityAdapter<IChildRateState>({
    selectId: selectChildRateId,
});

export const childrenLaundryPreferencesAdapter = createEntityAdapter<IChildLaundryPreferencesState>(
    {
        selectId: selectLaundryPreferencesId,
    },
);

export const childrenSettingsAdapter = createEntityAdapter<IChildSettingsState>({
    selectId: selectChildSettingsId,
});

const initialState = {
    selectedChildId: 0,

    children: childrenAdapter.getInitialState({
        status: RequestStatus.IDLE,
    }),

    childrenRates: childrenRatesAdapter.getInitialState({
        status: RequestStatus.IDLE,
    }),

    childrenLaundryPreferences: childrenLaundryPreferencesAdapter.getInitialState({
        status: RequestStatus.IDLE,
    }),

    childrenSettings: childrenSettingsAdapter.getInitialState({
        status: RequestStatus.IDLE,
    }),
};

export const fetchChildrenAsync = createAsyncThunk('children/fetchChildrenAsync', async () => {
    const { data } = await getChildren();
    return data.map(mapUserDetails);
});

export const fetchChildRatesAsync = createAsyncThunk(
    'children/fetchChildRatesAsync',
    async (userId: number) => {
        const { data } = await getChildRates(userId);
        return data;
    },
);

export const getChildBillingValidationStatusAsync = createAsyncThunk(
    'children/getChildBillingValidationStatusAsync',
    async (userId: number) => {
        const { data } = await getChildBillingValidationStatus(userId);
        return data;
    },
);

export const fetchChildLaundryPreferencesAsync = createAsyncThunk(
    'children/fetchChildLaundryPreferencesAsync',
    async (userId: number) => {
        const { data } = await getChildLaundryPreferences(userId);
        return data;
    },
);

export const fetchChildrenSettingsAsync = createAsyncThunk(
    'children/fetchChildrenSettingsAsync',
    async () => {
        const { data } = await getChildrenSettings();
        return data;
    },
);

export const setChildSettingsAsync = createAsyncThunk(
    'children/setChildSettingsAsync',
    async (payload: IUserSettings) => {
        await setChildSettings(payload);
    },
);

export const setChildrenSettingsAsync = createAsyncThunk(
    'children/setChildrenSettingsAsync',
    async (payload: ISettings) => {
        const { data } = await setChildrenSettings(payload);
        return data;
    },
);

const childrenSlice = createSlice({
    name: 'children',
    initialState,
    reducers: {
        selectChild: (state, { payload }: PayloadAction<number>) => {
            state.selectedChildId = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChildrenAsync.pending, (state) => {
                state.children.status = RequestStatus.LOADING;
            })
            .addCase(fetchChildrenAsync.fulfilled, (state, { payload: children }) => {
                state.children.status = RequestStatus.SUCCEEDED;
                childrenAdapter.upsertMany(
                    state.children,
                    children.map((child) => ({
                        status: RequestStatus.SUCCEEDED,
                        user: child,
                    })),
                );
            })
            .addCase(fetchChildrenAsync.rejected, (state) => {
                state.children.status = RequestStatus.FAILED;
            })

            .addCase(fetchChildRatesAsync.pending, (state, { meta }) => {
                const userId = meta.arg;
                childrenRatesAdapter.upsertOne(state.childrenRates, {
                    userId,
                    status: RequestStatus.LOADING,
                });
            })
            .addCase(fetchChildRatesAsync.fulfilled, (state, { payload, meta }) => {
                const userId = meta.arg;
                childrenRatesAdapter.updateOne(state.childrenRates, {
                    id: userId,
                    changes: {
                        status: RequestStatus.SUCCEEDED,
                        rate: payload,
                    },
                });
            })
            .addCase(fetchChildRatesAsync.rejected, (state, { meta }) => {
                const userId = meta.arg;
                childrenRatesAdapter.updateOne(state.childrenRates, {
                    id: userId,
                    changes: {
                        status: RequestStatus.FAILED,
                    },
                });
            })

            .addCase(getChildBillingValidationStatusAsync.fulfilled, (state, { payload, meta }) => {
                const userId = meta.arg;
                childrenAdapter.updateOne(state.children, {
                    id: userId,
                    changes: {
                        isCorrectBillingInfo: payload,
                    },
                });
            })

            .addCase(fetchChildLaundryPreferencesAsync.pending, (state, { meta }) => {
                const userId = meta.arg;
                childrenLaundryPreferencesAdapter.upsertOne(state.childrenLaundryPreferences, {
                    userId,
                    status: RequestStatus.LOADING,
                });
            })
            .addCase(fetchChildLaundryPreferencesAsync.fulfilled, (state, { payload, meta }) => {
                const userId = meta.arg;
                childrenLaundryPreferencesAdapter.upsertOne(state.childrenLaundryPreferences, {
                    userId,
                    status: RequestStatus.SUCCEEDED,
                    laundryPreferences: payload,
                });
            })
            .addCase(fetchChildLaundryPreferencesAsync.rejected, (state, { meta }) => {
                const userId = meta.arg;
                childrenLaundryPreferencesAdapter.updateOne(state.childrenLaundryPreferences, {
                    id: userId,
                    changes: {
                        status: RequestStatus.FAILED,
                    },
                });
            })

            .addCase(fetchChildrenSettingsAsync.pending, (state) => {
                state.childrenSettings.status = RequestStatus.LOADING;
            })
            .addCase(fetchChildrenSettingsAsync.fulfilled, (state, { payload }) => {
                state.childrenSettings.status = RequestStatus.SUCCEEDED;
                childrenSettingsAdapter.upsertMany(
                    state.childrenSettings,
                    payload.map((settings) => ({
                        status: RequestStatus.SUCCEEDED,
                        settings,
                    })),
                );
            })
            .addCase(fetchChildrenSettingsAsync.rejected, (state) => {
                state.childrenSettings.status = RequestStatus.FAILED;
            })

            .addCase(setChildSettingsAsync.pending, (state, { meta: { arg } }) => {
                childrenSettingsAdapter.upsertOne(state.childrenSettings, {
                    settings: {
                        ...defaultUserSettings,
                        ...state.childrenSettings.entities[arg.FK_PK_usersID]?.settings,
                        ...arg,
                    },
                    status: RequestStatus.LOADING,
                });
            })
            .addCase(setChildSettingsAsync.fulfilled, (state, { meta: { arg } }) => {
                childrenSettingsAdapter.updateOne(state.childrenSettings, {
                    id: arg.FK_PK_usersID,
                    changes: {
                        settings: {
                            ...state.childrenSettings.entities[arg.FK_PK_usersID]?.settings,
                            ...arg,
                        },
                        status: RequestStatus.SUCCEEDED,
                    },
                });
            })
            .addCase(setChildSettingsAsync.rejected, (state, { meta: { arg } }) => {
                childrenSettingsAdapter.updateOne(state.childrenSettings, {
                    id: arg.FK_PK_usersID,
                    changes: {
                        status: RequestStatus.FAILED,
                    },
                });
            })

            .addCase(setChildrenSettingsAsync.pending, (state) => {
                state.childrenSettings.status = RequestStatus.LOADING;
            })
            .addCase(setChildrenSettingsAsync.fulfilled, (state, { payload }) => {
                state.childrenSettings.status = RequestStatus.SUCCEEDED;
                childrenSettingsAdapter.upsertMany(
                    state.childrenSettings,
                    payload.map((settings) => ({
                        status: RequestStatus.SUCCEEDED,
                        settings,
                    })),
                );
            })
            .addCase(setChildrenSettingsAsync.rejected, (state) => {
                state.childrenSettings.status = RequestStatus.FAILED;
            });
    },
});

export const { selectChild } = childrenSlice.actions;

export default childrenSlice.reducer;
