import { configureStore } from '@reduxjs/toolkit';
import storeHelper from '~utils/store-helper';
import rootReducer from './root-reducer';
import { hnApiSlice } from './api';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(hnApiSlice.middleware),
});

// Wrapper for preventing circular dependencies
storeHelper.setupStore(store);

export default store;
