export const enum ToastIds {
    NO_INTERNET_CONNECTION = 'noInternetConnection',
    API_INTERNAL_ERROR = 'apiInternalError',
    SERVICE_UNAVAILABLE = 'serviceUnavailable',
    ACCOUNT_PLACED_ON_HOLD = 'accountPlacedOnHold',
    ACCOUNT_REMOVED_FROM_HOLD = 'accountRemovedFromHold',
    PLACE_ON_HOLD_REJECTED = 'placeOnHoldRejected',
    UPDATE_LAUNDRY_PREFERENCES_REJECTED = 'updateLaundryPreferencesRejected',
    LAUNDRY_PREFERENCES_UPDATED = 'laundryPreferencesUpdated',
    REFERRAL_EMAILS_SENT = 'referralEmailsSent',
    EMAILS_ALREADY_IN_USE = 'emailsAlreadyInUse',
    SEND_EMAIL_REJECTED = 'sendEmailRejected',
    NOTIFICATION_PREFERENCES_UPDATED = 'notificationPreferencesUpdated',
    NOTIFICATION_PREFERENCES_REJECTED = 'notificationPreferencesRejected',
    PASSWORD_UPDATED = 'passwordUpdated',
    PASSWORD_UPDATE_REJECTED = 'passwordUpdateRejected',
    ADDRESS_UPDATED = 'addressUpdated',
    ADDRESS_UPDATED_REJECTED = 'addressUpdatedRejected',
    UPDATE_BILLING_REJECTED = 'updateBillingRejected',
    DRIVER_TIPPING_REJECTED = 'driverTippingRejected',
    CANCEL_PICKUP_REJECTED = 'cancelPickupRejected',
    SCHEDULE_PICKUP_REJECTED = 'schedulePickupRejected',
    BILLING_INFORMATION_UPDATED = 'billingInformationUpdated',
    USER_DETAILS_UPDATED = 'userDetailsUpdated',
    USER_DETAILS_REJECTED = 'userDetailsRejected',
    FEEDBACK_USER_INFO_NOT_FOUND = 'feedbackUserInfoNotFound',
    REPROCESS_CARD_REJECTED = 'reprocessCardRejected',
    ADD_BILLING_ERROR = 'addBillingError',
    EXPIRED_SIGNUP_TOKEN = 'expiredSignupToken',
    TO_LATE_TO_CANCEL_ERROR = 'toLateToCancelError',
    SAME_DAY_CANCEL_REJECTED = 'sameDayCancelRejected',
    MAIN_INFO_REJECTED = 'mainInfoRejected',
    VACATION_HOLD = 'vacationHold',
    MANIFEST_RELEASED = 'manifestReleased',
    TOO_MANY_UPDATE_BILLING_REQUESTS = 'tooManyUpdateBillingRequests',
    TOO_MANY_GET_ACCOUNT_DELETION_CODE_REQUESTS = 'tooManyGetAcoountDeletionCodeRequests',
    TOO_MANY_ACCOUNT_DELETION_REQUESTS = 'tooManyAcoountDeletionRequests',
    SEND_DELETION_ACCOUNT_CODE_REJECTED = 'sendDeletionAccountCodeRejected',
    DELETE_ACCOUNT_REJECTED = 'deleteAccountRejected',
    ACCOUNT_DELETED = 'accountDeleted',
    CANCEL_SUCCESS = 'cancelSuccess',
    SAME_DAY_CANCEL_SUCCESS = 'sameDayCancelSuccess',
    SCHEDULE_SUCCESS = 'scheduleSuccess',
    INVALID_CREDIT_CARD = 'invalidCreditCard',
    TIPPING_PREFERENCES_UPDATED = 'tippingPreferencesUpdated',
}
