import { IUserLaundryPreferences } from '~constants/laundry-preferences';
import { IUserRates } from '~constants/rates';
import client from '~utils/hn-api';
import { IUserDetailsResponse } from '../../personal-info/types';
import { ISettings, IUserSettings } from '../types';

export const getChildren = () => client.user.get<IUserDetailsResponse[]>('/children');

export const getChildRates = (userId: number) => client.get<IUserRates>(`/users/${userId}/rates`);

export const getChildBillingValidationStatus = (userId: number) =>
    client.get<boolean>(`/users/${userId}/billing/validationStatus`);

export const getChildLaundryPreferences = (userId: number) =>
    client.get<IUserLaundryPreferences>(`/users/${userId}/preferences`);

export const getChildrenSettings = () => client.get<IUserSettings[]>(`/children/settings`);

export const setChildSettings = (userSettings: IUserSettings) =>
    client.patch(`/users/${userSettings.FK_PK_usersID}/settings`, userSettings);

export const setChildrenSettings = (userSettings: ISettings) =>
    client.patch<IUserSettings[]>(`/children/settings`, userSettings);
