import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import DialogTypes from '~constants/dialog-types';
import { openDialog } from '~store/modules/dialog';
import { PickupPlan } from '../account-info/types';
import { getLastDropOffForTipping } from './api';
import { ILastDropOffForTipping, ILastDropOffForTippingState } from './types';

const initialState = {
    lastDropOffForTipping: {
        data: null as ILastDropOffForTipping | null,
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as ILastDropOffForTippingState,
};

export const fetchLastDropOffForTipping = createAsyncThunk(
    'dropOffs/lastDropOffForTipping',
    async (openTippingDialog: boolean, { dispatch }) => {
        const { data } = await getLastDropOffForTipping();
        if (
            openTippingDialog &&
            data &&
            !data.tipsNotificationShown &&
            data.tipsPopup &&
            data.planType !== PickupPlan.Commercial &&
            !data.autoTippingAmount
        ) {
            dispatch(openDialog(DialogTypes.driverTipping));
        }
        return data;
    },
);

const dropOffsSlice = createSlice({
    name: 'dropOffs',
    initialState,
    reducers: {
        setTipAmount: (state, action: PayloadAction<number>) => {
            if (state.lastDropOffForTipping.data) {
                state.lastDropOffForTipping.data.amount = action.payload;
            }
        },
        setLastDropOffForTipping: (state, action: PayloadAction<ILastDropOffForTipping>) => {
            state.lastDropOffForTipping.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLastDropOffForTipping.pending, (state) => {
            state.lastDropOffForTipping.isLoading = true;
            state.lastDropOffForTipping.isSucceed = false;
            state.lastDropOffForTipping.isError = false;
        });
        builder.addCase(fetchLastDropOffForTipping.fulfilled, (state, { payload }) => {
            state.lastDropOffForTipping.isLoading = true;
            state.lastDropOffForTipping.isSucceed = true;
            state.lastDropOffForTipping.isError = false;
            state.lastDropOffForTipping.data = payload;
        });
        builder.addCase(fetchLastDropOffForTipping.rejected, (state) => {
            state.lastDropOffForTipping.isLoading = false;
            state.lastDropOffForTipping.isSucceed = false;
            state.lastDropOffForTipping.isError = true;
        });
    },
});

export const { setTipAmount, setLastDropOffForTipping } = dropOffsSlice.actions;

export default dropOffsSlice.reducer;
