import { IRequestState } from '~utils/hn-api';
import { IRequestState as INewRequestState } from '~utils/request-state';
import { IDictionary } from './dictionary';
import { IPromoCode } from '~store/modules/promo-codes/types';

export enum BillingInfoTabs {
    personalData = 'personal-data',
    billingAddress = 'billing-address',
    paymentInformation = 'payment-information',
}

export enum CardValidationResult {
    Verified = 1,
    AmericanExpressNotSupported = 2,
    InvalidCardLength = 3,
    InvalidCVVLength = 4,
    CardExpired = 5,
    CardExpireAtEndOfMonth = 6,
}

export enum ReprocessCardResult {
    Reprocessed = 1,
    DuplicateTransaction = 2,
    AddressNotMatchCardholderAddress = 3,
    CardHasExpired = 4,
    NoAvailableCard = 5,
    TransactionDeclined = 6,
}

const cardValidationErrors = {
    [CardValidationResult.Verified]: '',
    [CardValidationResult.AmericanExpressNotSupported]: 'Sorry, we do not accept American Express',
    [CardValidationResult.InvalidCardLength]: 'Please enter the correct card number.',
    [CardValidationResult.InvalidCVVLength]: 'Please enter the correct CVV number.',
    [CardValidationResult.CardExpired]: 'The card you entered has expired.',
    [CardValidationResult.CardExpireAtEndOfMonth]:
        'The card you are entering will expire at the end of this month.',
};

export const getCardValidationErrorText = (errorCode: CardValidationResult) =>
    cardValidationErrors[errorCode];

const cardReprocessErrors = {
    [ReprocessCardResult.Reprocessed]: '',
    [ReprocessCardResult.DuplicateTransaction]:
        'A duplicate transaction was submitted. Please wait two minutes and try again.',
    [ReprocessCardResult.AddressNotMatchCardholderAddress]:
        'The transaction has been declined. The address provided does not match the billing address of the cardholder.',
    [ReprocessCardResult.CardHasExpired]: 'The credit card has expired.',
    [ReprocessCardResult.NoAvailableCard]: 'No credit card on file.',
    [ReprocessCardResult.TransactionDeclined]: 'This transaction has been declined.',
};

export const addBillingError =
    'Your credit card declined. Please confirm that the information you entered is correct and then try again.';

export const avsError =
    'Your credit card declined. The address provided does not match billing address of cardholder.';

export const tooManyAttempts = 'Too many attempts. Please try again later or use another card.';

export const getCardReprocessErrorText = (errorCode: ReprocessCardResult) =>
    cardReprocessErrors[errorCode];

export interface IBillingInfo {
    PK_billingID: number;
    billingFirstName: string;
    billingLastName: string;
    billingAddress: string;
    billingAptSuite: string;
    billingCity: string;
    billingState: string;
    billingZipCode: string;
    billingPhone: string;
    isBillingMobilePhone: boolean;
    billingEmail: string;
    paymentType: number;
    cardNumber: string;
    expDate: string;
}

export interface IBillingInfoState extends IRequestState {
    data: IBillingInfo;
}

export interface ICardForm {
    cardNumber: string;
    cvv: string;
    expirationMouth: number;
    expirationYear: number;
}

export interface IBillingForm extends ICardForm {
    promoCode?: string;
}

export interface IUserBillingInfoForm extends IBillingForm {
    billingFirstName: string;
    billingLastName: string;
    billingAddress: string;
    billingAptSuite: string;
    billingCity: string;
    billingState: string;
    billingZipCode: string;
    billingPhone: string;
    billingEmail: string;
}

export interface ICardValidationResult {
    isAmericanExpress: boolean;
    validationResult: CardValidationResult;
}

export interface ICardValidationResultState extends IRequestState {
    data: ICardValidationResult;
}

export interface IPromoCodeValidationResultState extends INewRequestState {
    data: IPromoCode;
}

export const billingAddressMapper: IDictionary<string> = {
    address: 'billingAddress',
    city: 'billingCity',
    state: 'billingState',
    zipCode: 'billingZipCode',
};

export interface IReprocessDeclinedCardInfo {
    firstName: string;
    subscriberPhone: string;
    subscriberEmail: string;
    amount: number;
}

export interface IReprocessDeclinedCardResult {
    amount: number;
    status: ReprocessCardResult;
}

export interface IReprocessDeclinedCardInfoState extends IRequestState {
    data: IReprocessDeclinedCardInfo;
}

export interface IReprocessState extends IRequestState {
    isRepeatRequest: boolean;
}

export interface IBillingValidationState extends IRequestState {
    promoCode: boolean;
    card: CardValidationResult;
    promoCodeValue?: IPromoCode;
}
