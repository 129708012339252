import { RootState } from '~store/root-reducer';
import { getAccessToken } from '~utils/tokens';

export const getUserLoggedIn = () => !!getAccessToken();

export const getLoginRequestStatus = (state: RootState) => ({
    isLoginSuccess: state.auth.isLoginSuccess,
    isLoginError: state.auth.isLoginError,
    isSubmittingLogin: state.auth.isSubmittingLogin,
});

export const getSubmittingLogin = (state: RootState) => state.auth.isSubmittingLogin;

export const getLoginError = (state: RootState) => state.auth.isLoginError;

export const getLoginErrorMessages = (state: RootState) => state.auth.errorMessages;

export const getRecoveryPasswordRequestState = (state: RootState) => state.auth.recoveryPassword;

export const getResetPasswordRequestState = (state: RootState) => state.auth.resetPassword;
