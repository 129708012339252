import { AppBar, Box, Container, Toolbar } from '@mui/material';
import useBreakpoint from '~hooks/use-breakpoint';
import HeaderDesktop from './header-desktop';
import HeaderMobile from './header-mobile';

import style from './header.style';

export interface IHeader {
    showLogoOnly?: boolean;
    showFullLogo?: boolean;
}

const Header: React.FC<IHeader> = ({ showLogoOnly = false, showFullLogo = false }) => {
    const isDesktop = useBreakpoint();

    return (
        <Box data-testid='header'>
            <AppBar color='white' position='fixed' sx={style.appBar}>
                <Container maxWidth='xl'>
                    <Toolbar disableGutters>
                        {isDesktop ? (
                            <HeaderDesktop showLogoOnly={showLogoOnly} />
                        ) : (
                            <HeaderMobile showLogoOnly={showLogoOnly} showFullLogo={showFullLogo} />
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
};

export default Header;
