import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastIds } from '~constants/toast-ids';
import { isBadRequestError, isNotFoundError } from '~utils/errors';
import { IRequestState } from '~utils/hn-api';
import { getDescriptiveErrorText, showApiErrorToast, showErrorToast } from '~utils/toast-utils';
import { IAutomaticTippingState } from '~constants/driver-tipping';
import { fetchLastDropOffForTipping, setTipAmount } from '../drop-offs';
import {
    automaticTipAmountUpdateRequest,
    getAutomaticTipAmountRequest,
    setTipNotificationViewedRequest,
    tipDriverRequest,
} from './api';

const initialTipDriverStatus = {
    isLoading: false,
    isSucceed: false,
    isError: false,
} as IRequestState;

const initialState = {
    tipDriverStatus: initialTipDriverStatus,
    setTipNotificationStatus: {
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IRequestState,
    automaticTipping: {
        isLoading: false,
        isSucceed: false,
        isError: false,
        amount: undefined,
    } as IAutomaticTippingState,
    automaticTipAmountUpdateStatus: {
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IRequestState,
};

export const tipDriver = createAsyncThunk(
    'driverTipping/tipDriver',
    async (tippingInfo: any, { dispatch }) => {
        try {
            await tipDriverRequest(tippingInfo.orderId, {
                amount: tippingInfo.amount,
            });
            dispatch(setTipAmount(tippingInfo.amount));
        } catch (err) {
            if (isNotFoundError(err) || isBadRequestError(err)) {
                showErrorToast(getDescriptiveErrorText(err), ToastIds.DRIVER_TIPPING_REJECTED);
            } else {
                showApiErrorToast(err, ToastIds.DRIVER_TIPPING_REJECTED);
            }
            throw err;
        }
    },
);

export const setTipNotificationAsViewed = createAsyncThunk(
    'driverTipping/setTipNotificationAsViewed',
    async (orderId: number) => {
        await setTipNotificationViewedRequest(orderId);
    },
);

export const getAutomaticTipAmount = createAsyncThunk(
    'driverTipping/getAutomaticTipAmount',
    async () => {
        const { data } = await getAutomaticTipAmountRequest();
        return data;
    },
);

export const automaticTipAmountUpdate = createAsyncThunk(
    'driverTipping/automaticTipAmountUpdate',
    async (autoTippingInfo: any, { dispatch }) => {
        await automaticTipAmountUpdateRequest(autoTippingInfo);
        await dispatch(fetchLastDropOffForTipping(false));
        return autoTippingInfo;
    },
);

const driverTippingSlice = createSlice({
    name: 'driverTipping',
    initialState,
    reducers: {
        resetTipDriverStatus: (state) => {
            state.tipDriverStatus = initialTipDriverStatus;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(tipDriver.pending, (state) => {
            state.tipDriverStatus.isLoading = true;
            state.tipDriverStatus.isSucceed = false;
            state.tipDriverStatus.isError = false;
        });
        builder.addCase(tipDriver.fulfilled, (state) => {
            state.tipDriverStatus.isLoading = false;
            state.tipDriverStatus.isSucceed = true;
            state.tipDriverStatus.isError = false;
        });
        builder.addCase(tipDriver.rejected, (state) => {
            state.tipDriverStatus.isLoading = false;
            state.tipDriverStatus.isSucceed = false;
            state.tipDriverStatus.isError = true;
        });

        builder.addCase(setTipNotificationAsViewed.pending, (state) => {
            state.setTipNotificationStatus.isLoading = true;
            state.setTipNotificationStatus.isSucceed = false;
            state.setTipNotificationStatus.isError = false;
        });
        builder.addCase(setTipNotificationAsViewed.fulfilled, (state) => {
            state.setTipNotificationStatus.isLoading = false;
            state.setTipNotificationStatus.isSucceed = true;
            state.setTipNotificationStatus.isError = false;
        });
        builder.addCase(setTipNotificationAsViewed.rejected, (state) => {
            state.setTipNotificationStatus.isLoading = false;
            state.setTipNotificationStatus.isSucceed = false;
            state.setTipNotificationStatus.isError = true;
        });
        builder.addCase(getAutomaticTipAmount.pending, (state) => {
            state.automaticTipping.isLoading = true;
            state.automaticTipping.isSucceed = false;
        });
        builder.addCase(getAutomaticTipAmount.fulfilled, (state, { payload }) => {
            state.automaticTipping.isLoading = false;
            state.automaticTipping.isSucceed = true;
            state.automaticTipping.amount = payload;
        });
        builder.addCase(getAutomaticTipAmount.rejected, (state) => {
            state.automaticTipping.isLoading = false;
            state.automaticTipping.isSucceed = false;
        });
        builder.addCase(automaticTipAmountUpdate.pending, (state) => {
            state.automaticTipAmountUpdateStatus.isLoading = true;
            state.automaticTipAmountUpdateStatus.isSucceed = false;
            state.automaticTipAmountUpdateStatus.isError = false;
        });
        builder.addCase(automaticTipAmountUpdate.fulfilled, (state, { payload }) => {
            state.automaticTipAmountUpdateStatus.isLoading = false;
            state.automaticTipAmountUpdateStatus.isSucceed = true;
            state.automaticTipAmountUpdateStatus.isError = false;
            state.automaticTipping.amount = payload.amount;
        });
        builder.addCase(automaticTipAmountUpdate.rejected, (state) => {
            state.automaticTipAmountUpdateStatus.isLoading = false;
            state.automaticTipAmountUpdateStatus.isSucceed = false;
            state.automaticTipAmountUpdateStatus.isError = true;
        });
    },
});

export const { resetTipDriverStatus } = driverTippingSlice.actions;

export default driverTippingSlice.reducer;
