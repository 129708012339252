import { Store } from '@reduxjs/toolkit';

class StoreHelper {
    private store: Store | null = null;

    public setupStore(store: Store) {
        if (!this.store) {
            this.store = store;
        }
    }

    public dispatch(action: any) {
        this.store?.dispatch(action);
    }
}

export default new StoreHelper();
