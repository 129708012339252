import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    INotificationPreferencesRow,
    INotificationPreferencesState,
    IPreferencePayload,
    IUserNotificationPreferences,
    unsubscribedEmailPreferences,
} from '~constants/notification-preferences';
import { ToastIds } from '~constants/toast-ids';
import { isUnauthorizedError } from '~utils/errors';
import { IRequestState, ITokenRequest } from '~utils/hn-api';
import {
    notificationPreferencesToTableRows,
    rowsToNotificationPreferences,
} from '~utils/notification-preferences-mapper';
import { showApiErrorToast, showMessageToast } from '~utils/toast-utils';
import {
    getUserNotificationPreferencesByLinkRequest,
    getUserNotificationPreferencesRequest,
    saveUserNotificationPreferencesByLinkRequest,
    saveUserNotificationPreferencesRequest,
} from './api';

interface INotificationsState {
    preferences: INotificationPreferencesState;
    updatePreferences: IRequestState;
}

const initialState = {
    preferences: {
        initialValues: {} as IUserNotificationPreferences,
        data: [] as INotificationPreferencesRow[],
        isLoading: false,
        isSucceed: false,
    } as INotificationPreferencesState,
    updatePreferences: {
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IRequestState,
} as INotificationsState;

export const fetchNotificationPreferences = createAsyncThunk(
    'notifications/preferences',
    async () => {
        const { data } = await getUserNotificationPreferencesRequest();
        return data;
    },
);

export const saveNotificationPreferences = createAsyncThunk(
    'notifications/preferences-save',
    async (
        args: {
            notifyOnSuccess: boolean;
            singlePreference?: { [key: string]: boolean };
        },
        { getState }: any,
    ) => {
        try {
            const data = !args.singlePreference
                ? rowsToNotificationPreferences(
                      (getState().notifications as INotificationsState).preferences.data,
                  )
                : {};
            await saveUserNotificationPreferencesRequest({ ...data, ...args.singlePreference });

            if (args.notifyOnSuccess) {
                showMessageToast(
                    'Notification preferences successfully updated!',
                    ToastIds.NOTIFICATION_PREFERENCES_UPDATED,
                );
            }

            return data;
        } catch (err) {
            showApiErrorToast(err, ToastIds.NOTIFICATION_PREFERENCES_REJECTED);
            throw err;
        }
    },
);

export const fetchNotificationPreferencesByLink = createAsyncThunk(
    'notifications/preferencesByLink',
    async ({ token, onInvalidToken }: ITokenRequest) => {
        try {
            const { data } = await getUserNotificationPreferencesByLinkRequest(token);
            return notificationPreferencesToTableRows(data);
        } catch (err: any) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

export const saveNotificationPreferencesByLink = createAsyncThunk(
    'notifications/preferencesSaveByLink',
    async (
        args: { token: string; notifyOnSuccess: boolean; onInvalidToken: () => void },
        { getState }: any,
    ) => {
        try {
            const data = rowsToNotificationPreferences(getState().notifications.preferences.data);
            await saveUserNotificationPreferencesByLinkRequest(args.token, data);

            if (args.notifyOnSuccess) {
                showMessageToast(
                    'Notification preferences successfully updated!',
                    ToastIds.NOTIFICATION_PREFERENCES_UPDATED,
                );
            }
            return data;
        } catch (err) {
            if (isUnauthorizedError(err)) {
                args.onInvalidToken();
            } else {
                showApiErrorToast(err, ToastIds.NOTIFICATION_PREFERENCES_REJECTED);
                throw err;
            }
        }
    },
);

export const resetEmailNotificationPreferencesByLink = createAsyncThunk(
    'notifications/preferencesResetEmailByLink',
    async (args: { token: string; notifyOnSuccess: boolean; onInvalidToken: () => void }) => {
        try {
            const data = unsubscribedEmailPreferences;
            await saveUserNotificationPreferencesByLinkRequest(args.token, data);

            if (args.notifyOnSuccess) {
                showMessageToast(
                    'Notification preferences successfully updated!',
                    ToastIds.NOTIFICATION_PREFERENCES_UPDATED,
                );
            }
            return data;
        } catch (err) {
            if (isUnauthorizedError(err)) {
                args.onInvalidToken();
            } else {
                showApiErrorToast(err, ToastIds.NOTIFICATION_PREFERENCES_REJECTED);
                throw err;
            }
        }
    },
);

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        changePreference: (state, action: PayloadAction<IPreferencePayload>) => {
            const { index, type, value } = action.payload;
            state.preferences.data[index][type] = value;
        },
        resetPreferences: (state) => {
            state.preferences.data = notificationPreferencesToTableRows(
                state.preferences.initialValues,
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNotificationPreferences.pending, (state) => {
            state.preferences.isLoading = true;
            state.preferences.isSucceed = false;
        });
        builder.addCase(fetchNotificationPreferences.fulfilled, (state, { payload }) => {
            state.preferences.isLoading = false;
            state.preferences.isSucceed = true;
            state.preferences.initialValues = payload;
            state.preferences.data = notificationPreferencesToTableRows(payload);
        });
        builder.addCase(fetchNotificationPreferences.rejected, (state) => {
            state.preferences.isLoading = false;
            state.preferences.isSucceed = false;
        });

        builder.addCase(saveNotificationPreferences.pending, (state) => {
            state.updatePreferences.isLoading = true;
            state.updatePreferences.isSucceed = false;
        });
        builder.addCase(saveNotificationPreferences.fulfilled, (state, { payload }) => {
            state.updatePreferences.isLoading = false;
            state.updatePreferences.isSucceed = true;
            state.preferences.initialValues = payload;
        });
        builder.addCase(saveNotificationPreferences.rejected, (state) => {
            state.updatePreferences.isLoading = false;
            state.updatePreferences.isSucceed = false;
        });

        builder.addCase(fetchNotificationPreferencesByLink.pending, (state) => {
            state.preferences.isLoading = true;
            state.preferences.isSucceed = false;
        });
        builder.addCase(fetchNotificationPreferencesByLink.fulfilled, (state, action) => {
            state.preferences.isLoading = false;
            state.preferences.isSucceed = true;
            if (action.payload !== undefined) {
                state.preferences.data = action.payload;
            }
        });
        builder.addCase(fetchNotificationPreferencesByLink.rejected, (state) => {
            state.preferences.isLoading = false;
            state.preferences.isSucceed = false;
        });

        builder.addCase(saveNotificationPreferencesByLink.pending, (state) => {
            state.updatePreferences.isLoading = true;
            state.updatePreferences.isSucceed = false;
        });
        builder.addCase(saveNotificationPreferencesByLink.fulfilled, (state) => {
            state.updatePreferences.isLoading = false;
            state.updatePreferences.isSucceed = true;
        });
        builder.addCase(saveNotificationPreferencesByLink.rejected, (state) => {
            state.updatePreferences.isLoading = false;
            state.updatePreferences.isSucceed = false;
        });

        builder.addCase(resetEmailNotificationPreferencesByLink.rejected, (state) => {
            state.updatePreferences.isError = true;
        });
    },
});

export const { changePreference, resetPreferences } = notificationsSlice.actions;

export default notificationsSlice.reducer;
