import client from '~utils/hn-api';

export const getArrivalTimeRequest = () => client.user.get('/pickups/arrivalTime');

export const getArrivalTimeByLinkRequest = (token: string) =>
    client.get('/pickups/arrivalTime', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
