import { UpdatePickupPlan } from '~constants/schedule-pickup';
import { ToastIds } from '~constants/toast-ids';
import { isBadRequestError, isNotFoundError } from '~utils/errors';
import client from '~utils/hn-api';
import {
    defaultErrorMessage,
    showApiErrorToast,
    showErrorToast,
    showMessageToast,
} from '~utils/toast-utils';
import { INextPickup, IPickup } from '../../pickups/types';
import { IPickupResponse } from '../types';

export const getChildrenPickups = (endDate?: string) =>
    client.user.get<IPickupResponse[]>('/children/pickups', {
        params: {
            ...(endDate ? { endDate } : {}),
        },
    });

export const getChildPickups = (userId: number) =>
    client.get<IPickup[]>(`/users/${userId}/pickups`);

export const scheduleChildPickup = async (
    userId: number,
    planType: UpdatePickupPlan,
    date: string,
    pickupDayAsString = '',
    toastMessage = '',
) => {
    try {
        const data = { scheduledPickupDate: date, pickupDayAsString, expectedPlan: planType };
        await client.post(`/users/${userId}/pickups/updatePickups/schedule`, data);
        if (toastMessage) {
            showMessageToast(toastMessage, ToastIds.SCHEDULE_SUCCESS);
        }
    } catch (err) {
        if (isNotFoundError(err)) {
            showErrorToast(defaultErrorMessage, ToastIds.SCHEDULE_PICKUP_REJECTED);
        } else {
            showApiErrorToast(err, ToastIds.SCHEDULE_PICKUP_REJECTED);
        }
        throw err;
    }
};

export const cancelChildPickup = async (
    userId: number,
    planType: UpdatePickupPlan,
    date: string,
    pickupDayAsString = '',
    toastMessage = '',
) => {
    try {
        const data = { scheduledPickupDate: date, pickupDayAsString, expectedPlan: planType };
        await client.post(`/users/${userId}/pickups/updatePickups/canceling`, data);
        if (toastMessage) {
            showMessageToast(toastMessage, ToastIds.CANCEL_SUCCESS);
        }
    } catch (err) {
        if (isNotFoundError(err) || isBadRequestError(err)) {
            showErrorToast(defaultErrorMessage, ToastIds.CANCEL_PICKUP_REJECTED);
        } else {
            showApiErrorToast(err, ToastIds.CANCEL_PICKUP_REJECTED);
        }
        throw err;
    }
};

export const getNextChildPickupProgress = (userId: number) =>
    client.get<INextPickup>(`/users/${userId}/pickups/progress`);

export const cancelChildCurrentPickup = (userId: number) =>
    client.post(`/users/${userId}/pickups/cancelCurrent`);
