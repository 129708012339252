import { combineReducers } from '@reduxjs/toolkit';
import store from '.';
import accountInfo from './modules/account-info';
import address from './modules/address';
import auth from './modules/auth';
import billing from './modules/billing';
import dialog from './modules/dialog';
import driverTipping from './modules/driver-tipping';
import dropOffs from './modules/drop-offs';
import feedback from './modules/feedback';
import holdStatus from './modules/hold-status';
import laundryPreferences from './modules/laundry-preferences';
import link from './modules/link';
import notifications from './modules/notifications';
import passwordUpdate from './modules/password-update';
import personalInfo from './modules/personal-info';
import pickups from './modules/pickups';
import rates from './modules/rates';
import schedulePickup from './modules/schedule-pickup';
import signup from './modules/signup';
import arrivalTime from './modules/arrival-time';
import mainInfo from './modules/main-info';
import parentChild from './modules/parent-child';
import promoCodes from './modules/promo-codes';
import { hnApiSlice } from './api';

const combinedReducers = combineReducers({
    auth,
    dialog,
    billing,
    laundryPreferences,
    notifications,
    passwordUpdate,
    personalInfo,
    pickups,
    address,
    holdStatus,
    signup,
    schedulePickup,
    link,
    dropOffs,
    driverTipping,
    accountInfo,
    feedback,
    rates,
    arrivalTime,
    mainInfo,
    promoCodes,
    [hnApiSlice.reducerPath]: hnApiSlice.reducer,
    ...parentChild,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducers>;

const rootReducer = (state: any, action: any): RootState => {
    if (action.type === 'auth/resetState') {
        state = undefined;
    }
    return combinedReducers(state, action);
};

export default rootReducer;
