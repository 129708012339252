import { Box, Stack } from '@mui/material';
import FooterContacts from '../footer-contacts';
import FooterCopyright from '../footer-copyright';
import FooterDownloads from '../footer-downloads';

const FooterMobile = () => (
    <Stack alignItems='center' gap={{ xs: 5, md: 0 }} data-testid='footerMobile'>
        <Box />
        <FooterContacts />
        <FooterDownloads />
        <Box mb={3}>
            <FooterCopyright />
        </Box>
    </Stack>
);

export default FooterMobile;
