import { Stack } from '@mui/material';
import { UserMenuMobile } from '~components/user-menu';
import useAppSelector from '~hooks/use-app-selector';
import Logo from '~shared/logo';
import { getUserLoggedIn } from '~store/modules/auth/selectors';
import Logout from '../logout';

export interface IHeaderMobile {
    showLogoOnly?: boolean;
    showFullLogo?: boolean;
}

const HeaderMobile: React.FC<IHeaderMobile> = ({ showLogoOnly = false, showFullLogo = false }) => {
    const isUserLoggedIn = useAppSelector(getUserLoggedIn);

    return (
        <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            width='100%'
            data-testid='mobile-header'
        >
            <Logo isMobile={!showFullLogo} />

            {!showLogoOnly && <UserMenuMobile />}

            {showLogoOnly && isUserLoggedIn && <Logout />}
        </Stack>
    );
};

export default HeaderMobile;
