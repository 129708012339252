import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import LoadingLogo from '~shared/loading-logo';
import style from './page-loader.style';

interface IPageLoader {
    isAbsolute?: boolean;
    loadingDelayMs?: number;
}

const PageLoader: React.FC<IPageLoader> = ({ isAbsolute = false, loadingDelayMs = 0 }) => {
    const [showComponent, setShowComponent] = useState(loadingDelayMs === 0);

    useEffect(() => {
        if (loadingDelayMs > 0) {
            setTimeout(() => setShowComponent(true), loadingDelayMs);
        }
    }, []);

    return (
        showComponent && (
            <Box
                data-testid='page-loader'
                sx={isAbsolute ? style.absoluteLoadingContainer : style.relativeLoadingContainer}
            >
                <LoadingLogo sx={{ margin: 'auto' }} />
            </Box>
        )
    );
};

export default PageLoader;
