import { IUserNotificationPreferences } from '~constants/notification-preferences';
import client from '~utils/hn-api';

export const getUserNotificationPreferencesRequest = () =>
    client.user.get<IUserNotificationPreferences>('/notificationPreferences');

export const saveUserNotificationPreferencesRequest = (data: IUserNotificationPreferences) =>
    client.user.patch('/notificationPreferences', data);

export const getUserNotificationPreferencesByLinkRequest = (token: string) =>
    client.get<IUserNotificationPreferences>('/notificationPreferences', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const saveUserNotificationPreferencesByLinkRequest = (
    token: string,
    data: IUserNotificationPreferences,
) =>
    client.patch('/notificationPreferences', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
