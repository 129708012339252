import { Box, Stack } from '@mui/material';
import AppleStoreLogo from '~shared/apple-store-logo';
import GooglePlayLogo from '~shared/google-play-logo';
import Link from '~shared/link';
import Typography from '~shared/typography';
import style from './footer-downloads.style';

const FooterDownloads = () => (
    <Stack sx={style.root} data-testid='footerDownloads'>
        <Typography variant='subtitle1' color='action'>
            Downloads
        </Typography>

        <Box sx={style.appleLogo}>
            <Link
                id='appStoreLink'
                href='https://apps.apple.com/us/app/happynest-laundry-pickup/id1480519095?ls=1'
                target='_blank'
            >
                <AppleStoreLogo />
            </Link>
        </Box>

        <Box sx={style.googleLogo}>
            <Link
                id='googlePlayLink'
                href='https://play.google.com/store/apps/details?id=com.happynest.app'
                target='_blank'
            >
                <GooglePlayLogo />
            </Link>
        </Box>
    </Stack>
);

export default FooterDownloads;
