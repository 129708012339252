import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ToastIds } from '~constants/toast-ids';
import { isBadRequestError, isNotFoundError } from '~utils/errors';
import { RequestStatus } from '~utils/request-state';
import { showApiErrorToast, showErrorToast, showMessageToast } from '~utils/toast-utils';
import { IPickupPayload, IPickupState, ISameDayCancelPayload } from '../types';
import {
    cancelChildCurrentPickup,
    getChildPickups,
    getChildrenPickups,
    getNextChildPickupProgress,
} from './api';

export const selectPickupId = (pickup: IPickupState) => pickup.userId;

export const pickupsAdapter = createEntityAdapter<IPickupState>({
    selectId: selectPickupId,
});

const initialState = {
    pickups: pickupsAdapter.getInitialState({
        status: RequestStatus.IDLE,
    }),

    cancelChildSameDayStatus: RequestStatus.IDLE,
};

export const fetchPickupsAsync = createAsyncThunk('childPickups/fetchPickupsAsync', async () => {
    const { data } = await getChildrenPickups();
    return data;
});

export const fetchChildPickupsAsync = createAsyncThunk(
    'childPickups/fetchChildPickupsAsync',
    async ({ userId }: IPickupPayload) => {
        const { data } = await getChildPickups(userId);
        return data;
    },
);

export const fetchNextChildPickupProgressAsync = createAsyncThunk(
    'childPickups/fetchNextChildPickupProgressAsync',
    async (userId: number) => {
        const { data } = await getNextChildPickupProgress(userId);
        return data;
    },
);

export const cancelChildSameDayPickupAsync = createAsyncThunk(
    'childPickups/cancelChildSameDayPickupAsync',
    async (payload: ISameDayCancelPayload) => {
        try {
            await cancelChildCurrentPickup(payload.userId);
            if (payload.toastMessage) {
                showMessageToast(payload.toastMessage, ToastIds.SAME_DAY_CANCEL_SUCCESS);
            }
        } catch (err) {
            if (isNotFoundError(err) || isBadRequestError(err)) {
                showErrorToast(
                    "Unfortunately it's too late to cancel this pickup.",
                    ToastIds.SAME_DAY_CANCEL_REJECTED,
                );
            } else {
                showApiErrorToast(err, ToastIds.SAME_DAY_CANCEL_REJECTED);
            }
            throw err;
        }
    },
);

const childPickups = createSlice({
    name: 'childPickups',
    initialState,
    reducers: {
        resetChildCancelSameDayPickupDialog: (state) => {
            state.cancelChildSameDayStatus = RequestStatus.IDLE;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchChildPickupsAsync.pending, (state, { meta }) => {
                const { userId, hideLoading } = meta.arg;
                const loadingStatus = hideLoading ? RequestStatus.IDLE : RequestStatus.LOADING;
                pickupsAdapter.upsertOne(state.pickups, {
                    userId,
                    status: loadingStatus,
                    progressStatusRequestStatus: loadingStatus,
                });
            })
            .addCase(fetchChildPickupsAsync.fulfilled, (state, { payload, meta }) => {
                const { userId } = meta.arg;
                pickupsAdapter.updateOne(state.pickups, {
                    id: userId,
                    changes: {
                        pickupDates: payload,
                        status: RequestStatus.SUCCEEDED,
                        progressStatusRequestStatus: RequestStatus.SUCCEEDED,
                    },
                });
            })
            .addCase(fetchChildPickupsAsync.rejected, (state, { meta }) => {
                const { userId } = meta.arg;
                pickupsAdapter.updateOne(state.pickups, {
                    id: userId,
                    changes: {
                        status: RequestStatus.FAILED,
                        progressStatusRequestStatus: RequestStatus.FAILED,
                    },
                });
            })

            .addCase(fetchPickupsAsync.pending, (state) => {
                state.pickups.status = RequestStatus.LOADING;
            })
            .addCase(fetchPickupsAsync.fulfilled, (state, { payload: pickups }) => {
                state.pickups.status = RequestStatus.SUCCEEDED;
                pickupsAdapter.setMany(
                    state.pickups,
                    pickups.map((pickup) => ({
                        ...pickup,
                        status: RequestStatus.SUCCEEDED,
                        progressStatusRequestStatus: RequestStatus.SUCCEEDED,
                    })),
                );
            })
            .addCase(fetchPickupsAsync.rejected, (state) => {
                state.pickups.status = RequestStatus.FAILED;
            })

            .addCase(fetchNextChildPickupProgressAsync.pending, (state, { meta }) => {
                const userId = meta.arg;
                pickupsAdapter.updateOne(state.pickups, {
                    id: userId,
                    changes: {
                        progressStatusRequestStatus: RequestStatus.LOADING,
                    },
                });
            })
            .addCase(fetchNextChildPickupProgressAsync.fulfilled, (state, { payload, meta }) => {
                const userId = meta.arg;
                pickupsAdapter.updateOne(state.pickups, {
                    id: userId,
                    changes: {
                        progressStatus: payload,
                        progressStatusRequestStatus: RequestStatus.SUCCEEDED,
                    },
                });
            })
            .addCase(fetchNextChildPickupProgressAsync.rejected, (state, { meta }) => {
                const userId = meta.arg;
                pickupsAdapter.updateOne(state.pickups, {
                    id: userId,
                    changes: {
                        progressStatusRequestStatus: RequestStatus.FAILED,
                    },
                });
            })

            .addCase(cancelChildSameDayPickupAsync.pending, (state) => {
                state.cancelChildSameDayStatus = RequestStatus.LOADING;
            })
            .addCase(cancelChildSameDayPickupAsync.fulfilled, (state) => {
                state.cancelChildSameDayStatus = RequestStatus.SUCCEEDED;
            })
            .addCase(cancelChildSameDayPickupAsync.rejected, (state) => {
                state.cancelChildSameDayStatus = RequestStatus.FAILED;
            });
    },
});

export const { resetChildCancelSameDayPickupDialog } = childPickups.actions;

export default childPickups.reducer;
