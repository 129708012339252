export const DATE_FORMAT = 'yyyy-LL-dd';
export const DATE_TIME = 'yyyy-LL-dd HH:mm:ss';
export const TIME = 'HH:mm:ss';
export const TIME_SHORT = 'h:mm a';
export const DATE = 'LLLL d';
export const DAY_DATE = 'cccc d';
export const DAY_MONTH_DATE = 'cccc, LLLL d';
export const DAY_SHORT_MONTH_DATE = 'cccc, LLL. d';
export const SHORT_DAY_MONTH_DATE = 'ccc., LLL. d';
export const SHORT_MONTH_DATE_YEAR = 'MMM dd, yyyy';
