import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { LicenseInfo } from '@mui/x-license-pro';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from '~shared/error-boundary';
import PageLoader from '~shared/page-loader';
import { initDatadogLogClient } from '~utils/datadog';
import { injectStyle } from 'react-toastify/dist/inject-style';
import StyledToastContainer from '~shared/styled-toast-container';
import App from './app';
import store from './store';
import theme from './theme';

LicenseInfo.setLicenseKey(process.env.MUI_PRO_KEY as string);

initDatadogLogClient();
injectStyle();

const container = document.getElementById('root');
const root = createRoot(container as Element);

root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <BrowserRouter>
                <ErrorBoundary>
                    <Suspense fallback={<PageLoader isAbsolute />}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <CssBaseline />
                            <StyledToastContainer />
                            <App />
                        </LocalizationProvider>
                    </Suspense>
                </ErrorBoundary>
            </BrowserRouter>
        </Provider>
    </ThemeProvider>,
);
