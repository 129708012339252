import { Avatar as MuiAvatar } from '@mui/material';

interface IAvatar {
    size: number;
}

// TODO: implememnt avatar
const Avatar: React.FC<IAvatar> = ({ size }) => <MuiAvatar sx={{ width: size, height: size }} />;

export default Avatar;
