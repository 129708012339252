export enum RequestStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
}

export interface IRequestState {
    status: RequestStatus;
}

export const isIdle = (state?: RequestStatus) => state === RequestStatus.IDLE;

export const isLoading = (state?: RequestStatus) => state === RequestStatus.LOADING;

export const isSucceed = (state?: RequestStatus) => state === RequestStatus.SUCCEEDED;

export const isFailed = (state?: RequestStatus) => state === RequestStatus.FAILED;
