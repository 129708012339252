import { Box, Stack } from '@mui/material';
import ReferFriendButton from '~shared/refer-friend-button';
import FooterContacts from '../footer-contacts';
import FooterCopyright from '../footer-copyright';
import FooterDownloads from '../footer-downloads';

import style from './footer-desktop.style';

interface IFooterDesktop {
    showReferFriendButton: boolean;
}

const FooterDesktop: React.FC<IFooterDesktop> = ({ showReferFriendButton }) => (
    <Box sx={style.root} data-testid='footerDesktop'>
        <Stack direction='row' justifyContent='space-between' pt={6}>
            <FooterDownloads />
            <FooterContacts />
            {showReferFriendButton && (
                <Box>
                    <ReferFriendButton />
                </Box>
            )}
        </Stack>
        <Box pt={7} pb={3}>
            <FooterCopyright />
        </Box>
    </Box>
);

export default FooterDesktop;
