import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '~utils/request-state';
import { IInvoiceState, PaymentStatus } from '../types';
import { getChildrenInvoices } from './api';

const initialState = {
    invoices: {
        status: RequestStatus.IDLE,
        invoices: [],
    } as IInvoiceState,
};

export const fetchChildrenInvoicesAsync = createAsyncThunk(
    'childInvoices/fetchChildrenInvoicesAsync',
    async () => {
        const { data } = await getChildrenInvoices([
            PaymentStatus.Accrued,
            PaymentStatus.Declined,
            PaymentStatus.Paid,
            PaymentStatus.Voided,
            PaymentStatus.Refunded,
            PaymentStatus.PartialRefund,
        ]);
        return data;
    },
);

const childrenInvoicesSlice = createSlice({
    name: 'childInvoices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChildrenInvoicesAsync.pending, (state) => {
                state.invoices.status = RequestStatus.LOADING;
            })
            .addCase(fetchChildrenInvoicesAsync.fulfilled, (state, { payload }) => {
                state.invoices.status = RequestStatus.SUCCEEDED;
                state.invoices.invoices = payload;
            })
            .addCase(fetchChildrenInvoicesAsync.rejected, (state) => {
                state.invoices.status = RequestStatus.FAILED;
            });
    },
});

export default childrenInvoicesSlice.reducer;
