const style: Style = {
    section: {
        alignItems: { xs: 'center', md: 'flex-start' },
    },
    link1: {
        mt: {
            xs: 2,
            md: 3,
        },
    },
    link2: {
        mt: {
            xs: 1,
            md: 2,
        },
    },
};

export default style;
