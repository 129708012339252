import client from '~utils/hn-api';
import {
    IUserConstraints,
    IEmailAvailabilityCheckResult,
    IUserDetails,
    IUserDetailsResponse,
} from './types';

export const getUserDetailsRequest = () => client.user.get<IUserDetailsResponse>('/details');

export const updateUserDetailsRequest = (userDetails: IUserDetails) =>
    client.user.patch<IUserDetails>('/account', userDetails);

export const emailAvailabilityCheckRequest = (email: string) =>
    client.get<IEmailAvailabilityCheckResult>(`/isEmailInUse?email=${email}`);

export const getUserConstraintsRequest = () => client.user.get<IUserConstraints>('/constraints');

export const sendDeleteAccountCodeRequest = () =>
    client.user.get('/deleteAccount/confirmationCode');

export const deleteAccountRequest = (code: string) =>
    client.user.delete('/deleteAccount', { data: { confirmationCode: code } });
