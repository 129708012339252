import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'react-image-lightbox/style.css';
import useAppDispatch from '~hooks/use-app-dispatch';
import useAppSelector from '~hooks/use-app-selector';
import AuthenticatedRoutes from '~routes/authenticated-routes';
import NotAuthenticatedRoutes from '~routes/not-authenticated-routes';
import { getAccountInfoAsync } from '~store/modules/account-info';
import { getUserLoggedIn } from '~store/modules/auth/selectors';
import { selectUserEmail } from '~store/modules/personal-info/selectors';
import { sendImpactIdentifyInfo } from '~utils/marketing';
import { getUserId } from '~utils/tokens';

const App = () => {
    const dispatch = useAppDispatch();
    const userLoggedIn = useAppSelector(getUserLoggedIn);
    const userEmail = useAppSelector(selectUserEmail);
    const userId = getUserId();

    useEffect(() => {
        sendImpactIdentifyInfo(userId, userEmail);
    }, [userId, userEmail]);

    const [searchParams] = useSearchParams();
    const isLoginByAdmin = !!searchParams.get('token') && !!searchParams.get('userId');

    useEffect(() => {
        if (userLoggedIn && !isLoginByAdmin) {
            dispatch(getAccountInfoAsync());
        }
    }, [dispatch, userLoggedIn]);

    return userLoggedIn ? <AuthenticatedRoutes /> : <NotAuthenticatedRoutes />;
};

export default App;
