import { IAccountInfo } from '~store/modules/account-info/types';
import { LoginResponse } from './hn-api';

export const setAccessToken = (token: string) => localStorage.setItem('accessToken', token);
export const getAccessToken = () => localStorage.getItem('accessToken');
export const removeAccessToken = () => localStorage.removeItem('accessToken');

export const setRefreshToken = (token: string) => localStorage.setItem('refreshToken', token);
export const getRefreshToken = () => localStorage.getItem('refreshToken');
export const removeRefreshToken = () => localStorage.removeItem('refreshToken');

export const setUserId = (id: number) => localStorage.setItem('userId', id.toString());
export const getUserId = () => Number(localStorage.getItem('userId'));
export const removeUserId = () => localStorage.removeItem('userId');

export const setSubscriberId = (id: number) => localStorage.setItem('subscriberId', id.toString());
export const getSubscriberId = () => Number(localStorage.getItem('subscriberId'));
export const removeSubscriberId = () => localStorage.removeItem('subscriberId');

export const setSignupId = (id: number) => localStorage.setItem('signupId', id.toString());
export const getSignupId = () => Number(localStorage.getItem('signupId'));
export const removeSignupId = () => localStorage.removeItem('signupId');

export const setSignupToken = (token: string) => localStorage.setItem('signupToken', token);
export const getSignupToken = () => localStorage.getItem('signupToken');
export const removeSignupToken = () => localStorage.removeItem('signupToken');

export const parseAndSaveUserInfo = (userInfo: LoginResponse): IAccountInfo => {
    const {
        user: {
            PK_usersID: userId,
            FK_PK_subscriberID: subscriberId,
            FK_PK_signupID: signupId,
            customerHold,
            vacationHold,
            isCorrectBillingInfo,
            hasDeclinedInvoices,
            hasFailedPreAuths,
        },
        tokens: { accessToken, refreshToken },
        subscriber: { emailHello, subscriberPhone },
    } = {
        ...userInfo,
        user: {
            ...userInfo.user,
            customerHold: !!userInfo.user.customerHold,
            vacationHold: !!userInfo.user.vacationHold,
        },
    };

    setRefreshToken(refreshToken);
    setAccessToken(accessToken);
    setSignupId(signupId || 0);
    setUserId(userId);
    setSubscriberId(subscriberId);

    return {
        subscriberId,
        subscriberEmail: emailHello,
        subscriberPhone,
        customerHold,
        vacationHold,
        isCorrectBillingInfo,
        hasDeclinedInvoices,
        hasFailedPreAuths,
    };
};

export const removeUserInfo = () => {
    removeRefreshToken();
    removeAccessToken();
    removeSignupId();
    removeUserId();
    removeSubscriberId();
};
