const style: Style = {
    root: {
        typography: {
            xs: 'subtitle2',
            sm: 'subtitle1',
        },
        textAlign: 'center',
    },
};

export default style;
