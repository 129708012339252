import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setLastDropOffForTipping } from '../drop-offs';
import { getArrivalTimeByLinkRequest, getArrivalTimeRequest } from './api';
import { IArrivalTimeDataState } from './types';

const initialState = {
    arrivalTimeData: {
        data: null,
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IArrivalTimeDataState,
};

export const getArrivalTimeAsync = createAsyncThunk('arrivalTime/getArrivalTimeAsync', async () => {
    const { data } = await getArrivalTimeRequest();
    return data;
});

export const getArrivalTimeByLinkAsync = createAsyncThunk(
    'arrivalTime/getArrivalTimeByLinkAsync',
    async ({ orderId, token }: any, { dispatch }) => {
        const { data } = await getArrivalTimeByLinkRequest(token);
        if (data.lastDropOffForTipping) {
            // tipsPopup is set to 0 in order not to cause automatic display of the tipping dialog and to hide the "Don't show this again" option.
            dispatch(setLastDropOffForTipping({ ...data.lastDropOffForTipping, tipsPopup: 0 }));
        }
        return { orderId, data };
    },
);

const arrivalTimeSlice = createSlice({
    name: 'arrivalTime',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getArrivalTimeByLinkAsync.fulfilled, (state, action) => {
            const { data } = action.payload;
            state.arrivalTimeData.data = data;
            state.arrivalTimeData.isLoading = false;
            state.arrivalTimeData.isSucceed = true;
            state.arrivalTimeData.isError = false;
        });
        builder.addCase(getArrivalTimeByLinkAsync.pending, (state) => {
            state.arrivalTimeData.isLoading = true;
            state.arrivalTimeData.isSucceed = false;
            state.arrivalTimeData.isError = false;
        });
        builder.addCase(getArrivalTimeByLinkAsync.rejected, (state, action) => {
            state.arrivalTimeData.isLoading = false;
            state.arrivalTimeData.isSucceed = false;
            state.arrivalTimeData.isError = !!action.error;
        });

        builder.addCase(getArrivalTimeAsync.fulfilled, (state, { payload }) => {
            state.arrivalTimeData.data = payload;
            state.arrivalTimeData.isLoading = false;
            state.arrivalTimeData.isSucceed = true;
            state.arrivalTimeData.isError = false;
        });
        builder.addCase(getArrivalTimeAsync.pending, (state) => {
            state.arrivalTimeData.isLoading = true;
            state.arrivalTimeData.isSucceed = false;
            state.arrivalTimeData.isError = false;
        });
        builder.addCase(getArrivalTimeAsync.rejected, (state) => {
            state.arrivalTimeData.data = null;
            state.arrivalTimeData.isLoading = false;
            state.arrivalTimeData.isSucceed = false;
            state.arrivalTimeData.isError = true;
        });
    },
});

export default arrivalTimeSlice.reducer;
