const style: Style = {
    root: {
        width: 117,
        height: 48,
        fontSize: 16,
        color: 'black.main',
        gap: 1,
        mt: 2,
    },
};

export default style;
