import client from '~utils/hn-api';
import { getUserId } from '~utils/tokens';
import {
    ISignupBillingInfo,
    ISignupCardInfo,
    ISignupDeliveryInfo,
    ISignupInfo,
    ISignupPersonalInfo,
    IWaitingListForm,
} from './types';

export const fetchSignupInfoRequest = () => client.signup.get<ISignupInfo>('');

export const addToWaitingListRequest = (waitingListForm: IWaitingListForm) =>
    client.post('/signup/waitList', waitingListForm);

export const sendWaitingListEmailRequest = (waitingListForm: IWaitingListForm) =>
    client.post('/signup/waitList/email', waitingListForm);

export const setSignupPersonalInfoRequest = (personalInfo: ISignupPersonalInfo) =>
    client.post('/signup', personalInfo);

export const updateSignupPersonalInfoRequest = (personalInfo: ISignupPersonalInfo) =>
    client.signup.patch('/personal', personalInfo);

export const updateSignupDeliveryInfoRequest = (deliveryInfo: ISignupDeliveryInfo) =>
    client.signup.patch<ISignupInfo>('/address', deliveryInfo);

export const updateBillingInfoRequest = (billingInfo: ISignupBillingInfo) =>
    client.signup.patch('/billing', billingInfo);

export const updateOnboardingPassedRequest = (onboardingPassed: boolean) =>
    client.patch(`/signup/${getUserId()}/onboarding`, {
        onboardingPassed,
    });

export const getOnboardingPassedRequest = () => client.get(`/signup/${getUserId()}/onboarding`);

export const createAccount = (cardInfo: ISignupCardInfo) => client.signup.post('/create', cardInfo);

export const createAccountWithoutCard = (promoCodeInfo: any) =>
    client.signup.post('/createWithoutCard', promoCodeInfo);
