const baseContainer = {
    left: '50%;',
    bottom: '50%',
    transform: 'translate(-50%, 0)',
};

const style: Style = {
    absoluteLoadingContainer: {
        ...baseContainer,
        position: 'absolute',
    },
    relativeLoadingContainer: {
        ...baseContainer,
        position: 'relative',
        width: '100%',
    },
};

export default style;
