import { Link as MuiLink, LinkProps } from '@mui/material';
import style from './link.style';

// extend interface if needed
type ILink = LinkProps;

const Link: React.FC<ILink> = ({ children, href, sx, color = 'action', ...props }) => (
    <MuiLink
        href={href}
        color={`${color}.main`}
        sx={{ ...style.link, ...sx }}
        underline='hover'
        variant='body1'
        {...props}
    >
        {children}
    </MuiLink>
);

export default Link;
