import client, { IRequestState } from '~utils/hn-api';

export type Credentials = {
    username: string;
    password: string;
};

export type AdminCredentials = {
    userId: string;
    token: string;
};

export interface IPasswordRecovery extends IRequestState {
    userNotFound: boolean;
}

export const loginRequest = (credentials: Credentials) => client.post('/auth/login', credentials);

export const recoveryPasswordRequest = (email: string) =>
    client.post('/auth/password/recovery', { email });

export const resetPasswordRequest = (password: string, resetToken: string) =>
    client.post(
        '/auth/password/reset',
        {
            password,
        },
        {
            headers: {
                Authorization: `Bearer ${resetToken}`,
            },
        },
    );

export const loginAsCustomerRequest = (credentials: AdminCredentials) =>
    client.post(
        '/auth/admin',
        {
            userId: credentials.userId,
        },
        {
            headers: {
                Authorization: `Bearer ${credentials.token}`,
            },
        },
    );
