import client from '~utils/hn-api';
import { IUserPickupPlan } from './types';

export const getAccountInfoRequest = () => client.user.get('/baseAccountInfo');

export const getPickupPlanRequest = () => client.user.get<IUserPickupPlan>('/preferences/plan');

export const updateVacationHoldRequest = (vacationHold: boolean) =>
    client.user.patch<{ vacationHold: boolean }>('/vacation', { vacationHold });

export const getBillingValidationStatusRequest = () =>
    client.user.get<boolean>('/billing/validationStatus');

export const markEmailHealthcheckAsIgnoredRequest = () =>
    client.user.patch('/emailHealthcheck/skip');

export const removeEmailHealthcheckRequest = () => client.user.delete('/emailHealthcheck');
