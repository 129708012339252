import { addBillingError } from '~constants/billing-info';

const getStatusCode = (error: any, isFetchApi = false) =>
    isFetchApi ? error?.status : error?.response?.status;

export const isTimeoutError = (error: any, isFetchApi = false) =>
    isFetchApi ? error?.status === 'TIMEOUT_ERROR' : error?.code === 'ECONNABORTED';

export const isNoInternetError = (error: any, isFetchApi = false) =>
    isFetchApi ? error?.status === 'FETCH_ERROR' : error?.message === 'Network Error';

export const isBadRequestError = (error: any) => error?.response?.status === 400;

export const isNotFoundError = (error: any) => error?.response?.status === 404;

export const isUnauthorizedError = (error: any, isFetchApi = false) =>
    getStatusCode(error, isFetchApi) === 401;

export const isAccessDeniedError = (error: any) => error?.response?.status === 403;

export const isConflictError = (error: any) => error?.response?.status === 409;

export const isTooManyRequests = (error: any) => error?.response?.status === 429;

export const isServiceUnavailableError = (error: any) => error?.response?.status === 503;

export const isInternalServerError = (error: any, isFetchApi = false) =>
    getStatusCode(error, isFetchApi) === 500;

export const getBillingErrorMessage = (error: any) =>
    error.response?.data?.message || addBillingError;
