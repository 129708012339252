import {
    ISubscriberLaundryPreferences,
    IUserLaundryPreferences,
} from '~constants/laundry-preferences';
import client from '~utils/hn-api';

export const getSubscriberLaundryPreferencesRequest = () =>
    client.subscriber.get<ISubscriberLaundryPreferences>('/preferences');

export const getUserLaundryPreferencesRequest = () =>
    client.user.get<IUserLaundryPreferences>('/preferences');

export const updateUserLaundryInstructions = (instructionsLaundry: string) =>
    client.user.patch('/preferences/laundryInstructions', { instructionsLaundry });

export const updateUserFoldingInstructions = (instructionsFolding: string) =>
    client.user.patch('/preferences/foldingInstructions', { instructionsFolding });

export const updateUserDrivingInstructions = (instructionsDriving: string) =>
    client.user.patch('/preferences/drivingInstructions', { instructionsDriving });

export const updateUserLaundryPreferences = (
    laundryPreferences: Partial<IUserLaundryPreferences>,
) => client.user.patch('/preferences/laundry', laundryPreferences);
