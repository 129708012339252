import NotificationPreferences, {
    INotificationPreferencesRow,
    IUserNotificationPreferences,
} from '~constants/notification-preferences';

export const notificationPreferencesToTableRows = (source: IUserNotificationPreferences) => {
    const rows: INotificationPreferencesRow[] = [];
    NotificationPreferences.forEach((value, key) => {
        rows.push({
            name: key,
            ...(value.checkIfEnabled && {
                enabledBySubscriber: !!source[`${key}Enabled`],
            }),
            emailTextTitle: value.emailText,
            ...(value.popup && {
                popupTitle: value.popup,
                popup: !!source[`${key}Popup`],
            }),
            email: !!source[`${key}Email`],
            text: !!source[`${key}Text`],
        });
    });

    return rows;
};

export const rowsToNotificationPreferences = (rows: INotificationPreferencesRow[]) => {
    const request = {} as IUserNotificationPreferences;

    rows.forEach((row) => {
        request[`${row.name}Email`] = row.email ?? false;
        request[`${row.name}Text`] = row.text ?? false;

        if (row.popup !== undefined) {
            request[`${row.name}Popup`] = row.popup ?? false;
        }
    });

    return request;
};
