const style: Style = {
    root: {
        padding: '12px 0 12px 24px',
        gap: 2,

        '& .MuiListItemIcon-root': {
            minWidth: '0px',
        },

        '&:hover': {
            backgroundColor: 'grey2.main',
            svg: {
                color: 'black.main',
            },
        },
    },
};

export default style;
