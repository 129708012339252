import client from '~utils/hn-api';

export const getPickupPromptStatusRequest = (token: string) =>
    client.get('/pickups/pickupPromptStatus', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const schedulePickupRequest = (token: string) =>
    client.post('/pickups/schedulePickup', null, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
