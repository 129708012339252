import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { IPasswordReset } from '~constants/password-update';
import { isNotFoundError, isUnauthorizedError } from '~utils/errors';
import { IRequestState, RequestFailed } from '~utils/hn-api';
import {
    getAccessToken,
    parseAndSaveUserInfo,
    removeSignupId,
    removeSignupToken,
    removeUserInfo,
} from '~utils/tokens';
import { getAccountInfoAsync, setAccountInfo, setSignupQuery } from '../account-info';
import { resetTipDriverStatus } from '../driver-tipping';
import {
    AdminCredentials,
    Credentials,
    IPasswordRecovery,
    loginAsCustomerRequest,
    loginRequest,
    recoveryPasswordRequest,
    resetPasswordRequest,
} from './api';

export const loginUser = async (
    loginFunc: () => Promise<AxiosResponse<any, any>>,
    rejectWithValue: (value: unknown) => any,
    dispatch: (value: unknown) => any,
): Promise<any> => {
    try {
        const { data } = await loginFunc();
        const userInfo = parseAndSaveUserInfo(data);
        dispatch(setAccountInfo(userInfo));
        await dispatch(getAccountInfoAsync());
        removeSignupToken();
        removeSignupId();
    } catch (error: any) {
        const axiosError: AxiosError<RequestFailed, any> = error;

        if (!axiosError.response) {
            throw error;
        }

        return rejectWithValue(axiosError.response.data);
    }
};

export const login = createAsyncThunk(
    'auth/login',
    async (credentials: Credentials, { rejectWithValue, dispatch }) =>
        loginUser(() => loginRequest(credentials), rejectWithValue, dispatch),
);

export const logout = createAsyncThunk('auth/logout', (_, { dispatch }) => {
    dispatch(setSignupQuery(false));
    dispatch(resetTipDriverStatus());
    removeUserInfo();
});

export const recoveryPassword = createAsyncThunk(
    'auth/recoveryPassword',
    async (email: string, { rejectWithValue }) => {
        try {
            await recoveryPasswordRequest(email);
        } catch (error) {
            if (isNotFoundError(error)) {
                return rejectWithValue({ userNotFound: true });
            }
            throw error;
        }
    },
);

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (passwordReset: IPasswordReset) => {
        try {
            await resetPasswordRequest(passwordReset.password, passwordReset.token);
        } catch (err: any) {
            if (isUnauthorizedError(err)) {
                passwordReset.onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

export const loginAsCustomer = createAsyncThunk(
    'auth/admin',
    async (credentials: AdminCredentials, { rejectWithValue, dispatch }) =>
        loginUser(() => loginAsCustomerRequest(credentials), rejectWithValue, dispatch),
);

export const authInitialState = {
    isUserLoggedIn: !!getAccessToken(),
    isSubmittingLogin: false,
    isLoginError: false,
    isLoginSuccess: false,
    errorMessages: [],
    recoveryPassword: {
        isLoading: false,
        isSucceed: false,
        isError: false,
        userNotFound: false,
    } as IPasswordRecovery,
    resetPassword: {
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IRequestState,
};

// Base functionality for testing. TODO: add logic for reducers if needed
const authSlice = createSlice({
    name: 'auth',
    initialState: authInitialState,
    reducers: {
        resetPasswordRecovery: (state) => {
            state.recoveryPassword = {
                isLoading: false,
                isSucceed: false,
                isError: false,
                userNotFound: false,
            };
        },
        resetPasswordUpdating: (state) => {
            state.resetPassword = {
                isLoading: false,
                isError: false,
                isSucceed: false,
            };
        },
        resetState: () => {},
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            // Login request started
            state.errorMessages = [];
            state.isLoginError = false;
            state.isLoginSuccess = false;
            state.isSubmittingLogin = true;
        });
        builder.addCase(login.fulfilled, (state) => {
            // Success login reducer
            state.isUserLoggedIn = true;
            state.isLoginSuccess = true;
            state.isSubmittingLogin = false;
        });
        builder.addCase(login.rejected, (state, action) => {
            // Error login reducer
            if (action.payload !== undefined) {
                state.isLoginError = true;
            }
            state.isSubmittingLogin = false;
        });

        builder.addCase(loginAsCustomer.pending, (state) => {
            state.errorMessages = [];
            state.isLoginError = false;
            state.isLoginSuccess = false;
            state.isSubmittingLogin = true;
        });
        builder.addCase(loginAsCustomer.fulfilled, (state) => {
            state.isUserLoggedIn = true;
            state.isLoginSuccess = true;
            state.isSubmittingLogin = false;
        });
        builder.addCase(loginAsCustomer.rejected, (state) => {
            removeUserInfo();
            state.isLoginError = true;
            state.isSubmittingLogin = false;
        });

        builder.addCase(logout.fulfilled, (state) => {
            state.isUserLoggedIn = false;
        });

        builder.addCase(recoveryPassword.pending, (state) => {
            state.recoveryPassword.isLoading = true;
        });
        builder.addCase(recoveryPassword.fulfilled, (state) => {
            state.recoveryPassword.isLoading = false;
            state.recoveryPassword.isSucceed = true;
        });
        builder.addCase(recoveryPassword.rejected, (state, { payload }) => {
            state.recoveryPassword.isLoading = false;
            state.recoveryPassword.isSucceed = false;
            state.recoveryPassword.isError = true;
            if (payload.userNotFound) {
                state.recoveryPassword.userNotFound = true;
            }
        });

        builder.addCase(resetPassword.pending, (state) => {
            state.resetPassword.isLoading = true;
        });
        builder.addCase(resetPassword.fulfilled, (state) => {
            state.resetPassword.isLoading = false;
            state.resetPassword.isSucceed = true;
        });
        builder.addCase(resetPassword.rejected, (state) => {
            state.resetPassword.isLoading = false;
            state.resetPassword.isSucceed = false;
            state.resetPassword.isError = true;
        });
    },
});

export const { resetPasswordRecovery, resetPasswordUpdating, resetState } = authSlice.actions;

export default authSlice.reducer;
