import { IAddressSuggestion, IDeliveryAddress, IValidationResponse } from '~constants/address';
import { ZipCodeServicedType } from '~constants/zipcode-serviced-type';
import client from '~utils/hn-api';

// TODO: implement
export const getCitiesRequest = () =>
    Promise.resolve({
        data: [
            { city: 'Linkoln' },
            { city: 'Newport' },
            { city: 'Johnston' },
            { city: 'Virginia Beach' },
        ],
    });

export const getUserDeliveryAddressRequest = () => client.user.get<IDeliveryAddress>('/address');

export const getZipCodeValidationRequest = (zip: string) =>
    client.get<boolean>(`/addresses/zipCodeServiced/${zip}`);

export const getSubscriberZipCodeValidationRequest = (zip: string) =>
    client.subscriber.get<ZipCodeServicedType>(`/addresses/zipCodeServiced/${zip}`);

export const updateDeliveryAddressRequest = (values: IDeliveryAddress) =>
    client.user.patch<IDeliveryAddress>('/address', values);

export const validateAddressRequest = (values: IDeliveryAddress) =>
    client.post<IValidationResponse>('/addresses/validate', values);

export const getAddressSuggestions = (query = '') =>
    client.get<IAddressSuggestion[]>('/addresses/suggestions', { params: { query } });
