import { Dictionary } from '@reduxjs/toolkit';
import {
    IChildSettings,
    IChildSettingsState,
    IUserSettings,
} from '~store/modules/parent-child/types';
import { IUserDetails } from '~store/modules/personal-info/types';

export const getChildSetting = (
    user: IUserDetails,
    settings: Dictionary<IChildSettingsState>,
): IChildSettings => {
    const { userId } = user;
    const { settings: userSettings } = settings[userId] || {};
    return userSettings
        ? {
              ...userSettings,
              userDetails: user,
          }
        : {
              FK_PK_usersID: userId,
              overrideBillingInfo: false,
              overrideLaundryPreferences: false,
              overrideWashingPreferences: false,
              overrideFoldingPreferences: false,
              userDetails: user,
          };
};

export const defaultUserSettings: IUserSettings = {
    FK_PK_usersID: 0,
    overrideBillingInfo: false,
    overrideLaundryPreferences: false,
    overrideWashingPreferences: false,
    overrideFoldingPreferences: false,
};
