import { ConsoleApiName } from '@datadog/browser-core';
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';

function shouldWriteLogs() {
    return process.env.MODE !== 'dev';
}

const ignoreList = ['changes', 'localhost', 'googleadservices'];

// Allow XHR HappyNest only and all the not XHR errors
const isHappyNestXHRError = (message: string): boolean =>
    !message.includes('XHR') || message.includes('happynest');

export const initDatadogLogClient = () => {
    if (shouldWriteLogs()) {
        datadogLogs.setLoggerGlobalContext({
            host: window.location.hostname,
        });

        let datadogConfig: LogsInitConfiguration = {
            clientToken: process.env.DATADOG_KEY as string,
            site: 'datadoghq.com',
            service: 'HN-REACT-APP',
            forwardErrorsToLogs: true,
            forwardConsoleLogs: [ConsoleApiName.error],
            sampleRate: 100,
            beforeSend: (log) => {
                if (
                    !isHappyNestXHRError(log.message) ||
                    ignoreList.some((p) => log.message.includes(p))
                ) {
                    return false;
                }

                if (log.http && log.http.status_code >= 200 && log.http.status_code < 500) {
                    return false;
                }
            },
        };

        if (process.env.VERSION) {
            datadogConfig = { ...datadogConfig, version: process.env.VERSION };
        }

        datadogLogs.init(datadogConfig);
    }
};
