import Shadows from '~constants/shadows';

const style: Style = {
    appBar: {
        boxShadow: Shadows.small,
        zIndex: 1000, // drawer zIndex level plus one to be above side drawer
    },
};

export default style;
