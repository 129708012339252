import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ILaundryPreferenceChangedPayload,
    ISubscriberLaundryPreferences,
    ISubscriberLaundryPreferencesState,
    IUserLaundryPreferences,
    IUserLaundryPreferencesState,
} from '~constants/laundry-preferences';
import { ToastIds } from '~constants/toast-ids';
import { isNotFoundError } from '~utils/errors';
import { IRequestState } from '~utils/hn-api';
import {
    defaultErrorMessage,
    showApiErrorToast,
    showErrorToast,
    showMessageToast,
} from '~utils/toast-utils';
import { getAccountInfoAsync } from '../account-info';
import {
    getSubscriberLaundryPreferencesRequest,
    getUserLaundryPreferencesRequest,
    updateUserDrivingInstructions,
    updateUserFoldingInstructions,
    updateUserLaundryInstructions,
    updateUserLaundryPreferences,
} from './api';

const initialState = {
    userLaundryPreferences: {
        data: {} as IUserLaundryPreferences,
        isLoading: false,
        isSucceed: false,
    } as IUserLaundryPreferencesState,
    subscriberLaundryPreferences: {
        data: {} as ISubscriberLaundryPreferences,
        isLoading: false,
        isSucceed: false,
    } as ISubscriberLaundryPreferencesState,
    saveRequestState: {
        isLoading: false,
        isSucceed: false,
    } as IRequestState,
};

export const fetchUserLaundryPreferences = createAsyncThunk(
    'laundryPreferences/userPreferences',
    async () => {
        const { data } = await getUserLaundryPreferencesRequest();
        return data;
    },
);

export const fetchSubscriberLaundryPreferences = createAsyncThunk(
    'laundryPreferences/subscriberPreferences',
    async () => {
        const { data } = await getSubscriberLaundryPreferencesRequest();
        return data;
    },
);

export const saveUserLaundryPreferences = createAsyncThunk(
    'laundryPreferences/saveUserLaundryPreferences',
    async (
        arg: { preferences: Partial<IUserLaundryPreferences>; notifyOnSuccess?: boolean },
        { dispatch },
    ) => {
        const { preferences, notifyOnSuccess } = arg;

        try {
            await updateUserLaundryPreferences(preferences);

            if (notifyOnSuccess) {
                showMessageToast(
                    'Laundry preferences successfully updated!',
                    ToastIds.LAUNDRY_PREFERENCES_UPDATED,
                );
            }
        } catch (err) {
            dispatch(fetchUserLaundryPreferences());
            dispatch(getAccountInfoAsync());
            if (isNotFoundError(err)) {
                showErrorToast(defaultErrorMessage, ToastIds.UPDATE_LAUNDRY_PREFERENCES_REJECTED);
            } else {
                showApiErrorToast(err, ToastIds.UPDATE_LAUNDRY_PREFERENCES_REJECTED);
            }
            throw err;
        }
    },
);

export const savePickupDeliveryInstruction = createAsyncThunk(
    'laundryPreferences/savePickupDeliveryInstruction',
    async (arg: { instructionsDriving: string; notifyOnSuccess?: boolean }, { dispatch }) => {
        const { instructionsDriving, notifyOnSuccess } = arg;

        try {
            await updateUserDrivingInstructions(instructionsDriving);
            await dispatch(fetchUserLaundryPreferences());

            if (notifyOnSuccess) {
                showMessageToast(
                    'Your pickup and delivery instructions have been updated.',
                    ToastIds.LAUNDRY_PREFERENCES_UPDATED,
                );
            }
        } catch (err) {
            dispatch(fetchUserLaundryPreferences());
            dispatch(getAccountInfoAsync());
            if (isNotFoundError(err)) {
                showErrorToast(defaultErrorMessage, ToastIds.UPDATE_LAUNDRY_PREFERENCES_REJECTED);
            } else {
                showApiErrorToast(err, ToastIds.UPDATE_LAUNDRY_PREFERENCES_REJECTED);
            }
            throw err;
        }
    },
);

export const saveLaundryInstruction = createAsyncThunk(
    'laundryPreferences/saveLaundryInstruction',
    async (arg: { instructionsLaundry: string; notifyOnSuccess?: boolean }, { dispatch }) => {
        const { instructionsLaundry, notifyOnSuccess } = arg;

        try {
            await updateUserLaundryInstructions(instructionsLaundry);
            await dispatch(fetchUserLaundryPreferences());

            if (notifyOnSuccess) {
                showMessageToast(
                    'Your special laundry instructions have been updated.',
                    ToastIds.LAUNDRY_PREFERENCES_UPDATED,
                );
            }
        } catch (err) {
            dispatch(fetchUserLaundryPreferences());
            dispatch(getAccountInfoAsync());
            if (isNotFoundError(err)) {
                showErrorToast(defaultErrorMessage, ToastIds.UPDATE_LAUNDRY_PREFERENCES_REJECTED);
            } else {
                showApiErrorToast(err, ToastIds.UPDATE_LAUNDRY_PREFERENCES_REJECTED);
            }
            throw err;
        }
    },
);

export const saveFoldingInstruction = createAsyncThunk(
    'laundryPreferences/saveFoldingInstruction',
    async (arg: { instructionsFolding: string; notifyOnSuccess?: boolean }, { dispatch }) => {
        const { instructionsFolding, notifyOnSuccess } = arg;

        try {
            await updateUserFoldingInstructions(instructionsFolding);
            await dispatch(fetchUserLaundryPreferences());

            if (notifyOnSuccess) {
                showMessageToast(
                    'Your special folding instructions have been updated.',
                    ToastIds.LAUNDRY_PREFERENCES_UPDATED,
                );
            }
        } catch (err) {
            dispatch(fetchUserLaundryPreferences());
            dispatch(getAccountInfoAsync());
            if (isNotFoundError(err)) {
                showErrorToast(defaultErrorMessage, ToastIds.UPDATE_LAUNDRY_PREFERENCES_REJECTED);
            } else {
                showApiErrorToast(err, ToastIds.UPDATE_LAUNDRY_PREFERENCES_REJECTED);
            }
            throw err;
        }
    },
);

const laundryPreferencesSlice = createSlice({
    name: 'laundryPreferences',
    initialState,
    reducers: {
        changePreference: (state, action: PayloadAction<ILaundryPreferenceChangedPayload>) => {
            const { type, value } = action.payload;
            state.userLaundryPreferences.data[type] = value;
        },
        resetForm: (state) => {
            state.saveRequestState.isSucceed = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserLaundryPreferences.pending, (state) => {
            state.userLaundryPreferences.isSucceed = false;
            state.userLaundryPreferences.isLoading = true;
        });
        builder.addCase(fetchUserLaundryPreferences.fulfilled, (state, action) => {
            state.userLaundryPreferences.data = action.payload;
            state.userLaundryPreferences.isLoading = false;
            state.userLaundryPreferences.isSucceed = true;
        });
        builder.addCase(fetchUserLaundryPreferences.rejected, (state) => {
            state.userLaundryPreferences.isLoading = false;
            state.userLaundryPreferences.isSucceed = false;
        });
        builder.addCase(fetchSubscriberLaundryPreferences.pending, (state) => {
            state.subscriberLaundryPreferences.isSucceed = false;
            state.subscriberLaundryPreferences.isLoading = true;
        });
        builder.addCase(fetchSubscriberLaundryPreferences.fulfilled, (state, action) => {
            state.subscriberLaundryPreferences.data = action.payload;
            state.subscriberLaundryPreferences.isLoading = false;
            state.subscriberLaundryPreferences.isSucceed = true;
        });
        builder.addCase(fetchSubscriberLaundryPreferences.rejected, (state) => {
            state.subscriberLaundryPreferences.isLoading = false;
            state.subscriberLaundryPreferences.isSucceed = false;
        });
        builder.addCase(saveUserLaundryPreferences.pending, (state) => {
            state.saveRequestState.isSucceed = false;
            state.saveRequestState.isLoading = true;
        });
        builder.addCase(saveUserLaundryPreferences.fulfilled, (state, { meta: { arg } }) => {
            state.userLaundryPreferences.data = {
                ...state.userLaundryPreferences.data,
                ...arg.preferences,
            };
            state.saveRequestState.isLoading = false;
            state.saveRequestState.isSucceed = true;
        });
        builder.addCase(saveUserLaundryPreferences.rejected, (state) => {
            state.saveRequestState.isLoading = false;
            state.saveRequestState.isSucceed = false;
        });
    },
});

export const { changePreference, resetForm: resetLaundryPreferencesForm } =
    laundryPreferencesSlice.actions;

export default laundryPreferencesSlice.reducer;
