import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isUnauthorizedError } from '~utils/errors';
import { IRequestState, ITokenRequest } from '~utils/hn-api';
import {
    getFeedbackInfoRequest,
    recordFeedbackFollowUpRequest,
    recordFeedbackRequest,
} from './api';
import { IFeedbackFollowUpPayload, IFeedbackState } from './types';

const initialState = {
    feedback: {
        data: undefined,
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IFeedbackState,

    recordFeedbackFollowUp: {
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IRequestState,
};

export const feedbackInfoAsync = createAsyncThunk(
    'feedback/feedbackInfoAsync',
    async ({ token, onInvalidToken }: ITokenRequest) => {
        try {
            const { data: feedbackInfo } = await getFeedbackInfoRequest(token);
            if (!feedbackInfo.isAlreadySent) {
                await recordFeedbackRequest(token);
            }
            return feedbackInfo;
        } catch (err: any) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

export const recordFeedbackFollowUpAsync = createAsyncThunk(
    'feedback/recordFeedbackFollowUpAsync',
    async (feedbackPayload: IFeedbackFollowUpPayload) => {
        try {
            await recordFeedbackFollowUpRequest(feedbackPayload.token, feedbackPayload.description);
        } catch (err: any) {
            if (isUnauthorizedError(err)) {
                feedbackPayload.onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(feedbackInfoAsync.pending, (state) => {
                state.feedback.isLoading = true;
                state.feedback.isError = false;
                state.feedback.isSucceed = false;
            })
            .addCase(feedbackInfoAsync.fulfilled, (state, { payload: feedback }) => {
                state.feedback.isLoading = false;
                state.feedback.isSucceed = true;
                state.feedback.isError = false;
                if (feedback !== undefined) {
                    state.feedback.data = feedback;
                }
            })
            .addCase(feedbackInfoAsync.rejected, (state) => {
                state.feedback.isLoading = false;
                state.feedback.isSucceed = false;
                state.feedback.isError = true;
            })

            .addCase(recordFeedbackFollowUpAsync.pending, (state) => {
                state.recordFeedbackFollowUp.isLoading = true;
                state.recordFeedbackFollowUp.isError = false;
                state.recordFeedbackFollowUp.isSucceed = false;
            })
            .addCase(recordFeedbackFollowUpAsync.fulfilled, (state) => {
                state.recordFeedbackFollowUp.isLoading = false;
                state.recordFeedbackFollowUp.isSucceed = true;
                state.recordFeedbackFollowUp.isError = false;
            })
            .addCase(recordFeedbackFollowUpAsync.rejected, (state) => {
                state.recordFeedbackFollowUp.isLoading = false;
                state.recordFeedbackFollowUp.isSucceed = false;
                state.recordFeedbackFollowUp.isError = true;
            });
    },
});

export default feedbackSlice.reducer;
