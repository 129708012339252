/* eslint-disable @typescript-eslint/no-explicit-any */
import log from 'loglevel';

// TODO: Implement logging service logic
const error = (...message: any[]) => log.error(...message);

const debug = (...message: any[]) => log.debug(...message);

const warn = (...message: any[]) => log.warn(...message);

const info = (...message: any[]) => log.info(...message);

export default {
    error,
    debug,
    warn,
    info,
};
