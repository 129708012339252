import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const LoginPage = lazy(() => import('~components/login-page'));
const Feedback = lazy(() => import('~components/feedback'));
const Signup = lazy(() => import('~components/signup-page'));
const PasswordRecoveryPage = lazy(() => import('~components/password-recovery-page'));
const PasswordResetPage = lazy(() => import('~components/password-reset-page'));
const OptInPage = lazy(() => import('~components/opt-in-page'));
const NotificationsPage = lazy(() => import('~components/notifications-page'));
const CancelPickupPage = lazy(() => import('~components/cancel-pickup-page'));
const ShortLinkMiddleware = lazy(() => import('~shared/short-link-middleware'));
const LoginAdmin = lazy(() => import('~components/login-admin'));
const ReprocessFailedPreAuthPage = lazy(() => import('~components/reprocess-failed-pre-auth-page'));
const ArrivalTimePage = lazy(() => import('~components/arrival-time-page'));
const ReceiveEmailPage = lazy(() => import('~components/receive-email-page'));
const UnsubscribePage = lazy(() => import('~components/unsubscribe-page'));
const SettleUpPage = lazy(() => import('~components/settle-up-page'));

const NotAuthenticatedRoutes = () => (
    <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/feedback' element={<Feedback />} />
        <Route path='/login/admin' element={<LoginAdmin />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/resetPasswordRequest' element={<PasswordRecoveryPage />} />
        <Route path='/resetPassword' element={<PasswordResetPage />} />
        <Route path='/schedulePickup' element={<OptInPage />} />
        <Route path='/arrivalTime' element={<ArrivalTimePage />} />
        <Route path='/updateNotifications' element={<NotificationsPage />} />
        <Route path='/cancelPickup' element={<CancelPickupPage />} />
        <Route path='/failedPreAuth' element={<ReprocessFailedPreAuthPage />} />
        <Route path='/settleUp' element={<SettleUpPage />} />
        <Route path='/whyReceiving' element={<ReceiveEmailPage />} />
        <Route path='/unsubscribe' element={<UnsubscribePage />} />
        <Route path='/s/:shortLink' element={<ShortLinkMiddleware />} />
        {/* <Route path='*'>TODO: Make decision what to do with rest path</Route> */}
        <Route path='/' element={<Navigate to='/login' replace />} />
        <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
);

export default NotAuthenticatedRoutes;
