const style: Style = {
    root: {
        flexDirection: {
            xs: 'column-reverse',
            md: 'row',
        },
        alignItems: 'center',
        gap: 3,
    },
};

export default style;
