import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastIds } from '~constants/toast-ids';
import { isBadRequestError, isTooManyRequests } from '~utils/errors';
import { IRequestState } from '~utils/hn-api';
import { showApiErrorToast, showErrorToast, showMessageToast } from '~utils/toast-utils';
import {
    deleteAccountRequest,
    emailAvailabilityCheckRequest,
    getUserConstraintsRequest,
    getUserDetailsRequest,
    sendDeleteAccountCodeRequest,
    updateUserDetailsRequest,
} from './api';
import {
    IEmailAvailabilityCheckResult,
    IEmailAvailabilityCheckResultState,
    IPersonaInfoState,
    ISendDeleteAccountCodeState,
    IUserConstraints,
    IUserConstraintsState,
    IUserDetails,
    mapUserDetails,
} from './types';

const tooManyDeletionCodeRequests = 'Too many attempts. Please try again later.';
const tooManyDeletionAttempts = 'Too many attempts. Please send a SMS with the new code.';

const initialState = {
    userDetails: { firstName: '', lastName: '', email: '', phone: '' },
    emailAvailability: {
        data: { result: true } as IEmailAvailabilityCheckResult,
        isLoading: false,
        isSucceed: false,
    } as IEmailAvailabilityCheckResultState,
    userConstraints: {
        data: {
            hasDeclinedInvoices: false,
            hasActiveManifest: false,
            hasMissedDropOff: false,
            hasIncompleteDelivery: false,
            hasScheduledDropOff: false,
        } as IUserConstraints,
        isLoading: false,
        isSucceed: false,
    } as IUserConstraintsState,
    sendDeleteAccountCode: {
        isLoading: false,
        isSucceed: false,
    } as ISendDeleteAccountCodeState,
    deleteAccount: {
        isLoading: false,
        isSucceed: false,
    } as IRequestState,
} as IPersonaInfoState;

export const getUserDetailsAsync = createAsyncThunk(
    'personalInfo/getUserDetailsAsync',
    async () => {
        const { data } = await getUserDetailsRequest();
        return mapUserDetails(data);
    },
);

export const updateUserDetailsAsync = createAsyncThunk(
    'personalInfo/updateUserDetailsAsync',
    async (userDetails: IUserDetails) => {
        try {
            await updateUserDetailsRequest(userDetails);
            // TODO: replace with return from api if implement
            return userDetails;
        } catch (err) {
            showApiErrorToast(err, ToastIds.USER_DETAILS_REJECTED);
            throw err;
        }
    },
);

export const emailAvailabilityCheck = createAsyncThunk(
    '/personalInfo/checkEmailAvailability',
    async (email: string) => {
        const { data } = await emailAvailabilityCheckRequest(email);
        return data;
    },
);

export const fetchUserConstraints = createAsyncThunk(
    '/personalInfo/getUserConstraints',
    async () => {
        const { data } = await getUserConstraintsRequest();
        return data;
    },
);

export const sendDeleteAccountCode = createAsyncThunk(
    '/personalInfo/sendDeleteAccountCode',
    async () => {
        try {
            await sendDeleteAccountCodeRequest();
        } catch (err: any) {
            // In case user has deletion blockers
            if (isBadRequestError(err)) {
                showApiErrorToast(err, ToastIds.SEND_DELETION_ACCOUNT_CODE_REJECTED);
            }
            // In case the server doesn't pass the time to send a new message
            if (isTooManyRequests(err)) {
                showErrorToast(
                    tooManyDeletionCodeRequests,
                    ToastIds.TOO_MANY_GET_ACCOUNT_DELETION_CODE_REQUESTS,
                );
            }
            throw err;
        }
    },
);

export const deleteAccount = createAsyncThunk(
    '/personalInfo/deleteAccount',
    async (code: string) => {
        try {
            await deleteAccountRequest(code);
        } catch (err: any) {
            // In case the code is wrong or user has deletion blockers
            if (isBadRequestError(err)) {
                showApiErrorToast(err, ToastIds.DELETE_ACCOUNT_REJECTED);
            }
            // In case a user enters the code incorrectly several times
            if (isTooManyRequests(err)) {
                showErrorToast(
                    tooManyDeletionAttempts,
                    ToastIds.TOO_MANY_ACCOUNT_DELETION_REQUESTS,
                );
            }
            throw err;
        }
    },
);

const personalInfoSlice = createSlice({
    name: 'personalInfo',
    initialState,
    reducers: {
        resetDeleteAccount: (state) => {
            state.deleteAccount = {
                isLoading: false,
                isSucceed: false,
                isError: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetailsAsync.fulfilled, (state, action) => {
                state.userDetails = action.payload;
            })
            .addCase(updateUserDetailsAsync.fulfilled, (state, action) => {
                state.userDetails = action.payload;
                // TODO: change to required title
                showMessageToast(
                    'Personal Information successfully updated!',
                    ToastIds.USER_DETAILS_UPDATED,
                );
            })
            .addCase(emailAvailabilityCheck.pending, (state) => {
                state.emailAvailability.isLoading = true;
                state.emailAvailability.isSucceed = false;
            })
            .addCase(emailAvailabilityCheck.fulfilled, (state, action) => {
                state.emailAvailability.isLoading = false;
                state.emailAvailability.isSucceed = true;
                state.emailAvailability.data.result = !action.payload.result;
            })
            .addCase(emailAvailabilityCheck.rejected, (state) => {
                state.emailAvailability.isLoading = false;
                state.emailAvailability.isSucceed = false;
            })
            .addCase(fetchUserConstraints.pending, (state) => {
                state.userConstraints.isLoading = true;
                state.userConstraints.isSucceed = false;
            })
            .addCase(fetchUserConstraints.fulfilled, (state, action) => {
                state.userConstraints.isLoading = false;
                state.userConstraints.isSucceed = true;
                state.userConstraints.data = action.payload;
            })
            .addCase(fetchUserConstraints.rejected, (state) => {
                state.userConstraints.isLoading = false;
                state.userConstraints.isSucceed = false;
            })
            .addCase(sendDeleteAccountCode.pending, (state) => {
                state.sendDeleteAccountCode.isLoading = true;
                state.sendDeleteAccountCode.isSucceed = false;
            })
            .addCase(sendDeleteAccountCode.fulfilled, (state) => {
                state.sendDeleteAccountCode.isLoading = false;
                state.sendDeleteAccountCode.isSucceed = true;
                state.sendDeleteAccountCode.requestCompletionTime = new Date().getTime();
            })
            .addCase(sendDeleteAccountCode.rejected, (state) => {
                state.sendDeleteAccountCode.isLoading = false;
                state.sendDeleteAccountCode.isSucceed = false;
                // Restart the SMS timer even if the request ended with an error
                state.sendDeleteAccountCode.requestCompletionTime = new Date().getTime();
            })
            .addCase(deleteAccount.pending, (state) => {
                state.deleteAccount.isLoading = true;
                state.deleteAccount.isSucceed = false;
            })
            .addCase(deleteAccount.fulfilled, (state) => {
                state.deleteAccount.isLoading = false;
                state.deleteAccount.isSucceed = true;
            })
            .addCase(deleteAccount.rejected, (state) => {
                state.deleteAccount.isLoading = false;
                state.deleteAccount.isSucceed = false;
            });
    },
});

export const { resetDeleteAccount } = personalInfoSlice.actions;

export default personalInfoSlice.reducer;
