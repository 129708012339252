import client from '~utils/hn-api';

export const getFeedbackInfoRequest = (token: string) =>
    client.get('/feedback', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const recordFeedbackRequest = (token: string) =>
    client.post(
        '/feedback',
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );

export const recordFeedbackFollowUpRequest = (token: string, description: string) =>
    client.patch(
        '/feedback',
        { description },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
