import { Box, SvgIconProps } from '@mui/material';
import HnLogoVertical from '~assets/svg/hn-logo-vertical.svg';
import style from './loading-logo.style';

const LoadingLogo: React.FC<SvgIconProps> = ({ sx }) => (
    <Box sx={{ ...sx, ...style.hnLogo }}>
        <HnLogoVertical />
    </Box>
);

export default LoadingLogo;
