import { IRequestState } from '~utils/hn-api';
import { PlanType } from '../account-info/types';

// TODO: remove 'IUserDetailsResponse' and merge 'IUserDetailsBase' with 'IUserDetails' if remove mapping
interface IUserDetailsBase {
    phone: string;
    email: string;
}

export interface IUserDetailsResponse extends IUserDetailsBase {
    firstname: string;
    lastname: string;
    creditAmount: number;
    companyName: string;
    showCompanyName: boolean;
    PK_usersID: number;
    FK_PK_subscriberID: number;
    planType?: PlanType;
}

export interface IUserDetails extends IUserDetailsBase {
    firstName: string;
    lastName: string;
    creditAmount: number;
    companyName: string;
    showCompanyName: boolean;
    userId: number;
    subscriberId: number;
    planType?: PlanType;
}

export interface IEmailAvailabilityCheckResult {
    result: boolean;
}

export interface IEmailAvailabilityCheckResultState extends IRequestState {
    data: IEmailAvailabilityCheckResult;
}
export interface IUserConstraints {
    hasDeclinedInvoices: boolean;
    hasActiveManifest: boolean;
    hasMissedDropOff: boolean;
    hasIncompleteDelivery: boolean;
    hasScheduledDropOff: boolean;
}

export interface IUserConstraintsState extends IRequestState {
    data: IUserConstraints;
}

export interface IDeleteAccount {
    code: string;
}

export interface ISendDeleteAccountCodeState extends IRequestState {
    requestCompletionTime: number;
}

export interface IPersonaInfoState {
    userDetails: IUserDetails;
    emailAvailability: IEmailAvailabilityCheckResultState;
    userConstraints: IUserConstraintsState;
    sendDeleteAccountCode: ISendDeleteAccountCodeState;
    deleteAccount: IRequestState;
}

export const mapUserDetails = (user: IUserDetailsResponse): IUserDetails => ({
    firstName: user.firstname,
    lastName: user.lastname,
    email: user.email,
    phone: user.phone,
    creditAmount: user.creditAmount,
    companyName: user.companyName,
    showCompanyName: Boolean(user.showCompanyName),
    userId: user.PK_usersID,
    subscriberId: user.FK_PK_subscriberID,
    planType: user.planType,
});
