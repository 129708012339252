import { keyframes } from '@emotion/react';

const badgeAnimation = keyframes`
    0% {
        transform: scale(0.8);
        opacity: 0.6;
    }
    50% {
        transform: scale(0.95);
        opacity: 1;
    }
    100% {
        transform: scale(0.8);
        opacity: 0.6;
    }
`;

const style: Style = {
    hnLogo: {
        width: 'fit-content',
        height: 'fit-content',
        animation: `${badgeAnimation} 4s infinite ease-in-out`,
    },
};

export default style;
