import { Box, Stack, SxProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DialogTypes from '~constants/dialog-types';
import useAppDispatch from '~hooks/use-app-dispatch';
import useAppSelector from '~hooks/use-app-selector';
import Avatar from '~shared/avatar';
import {
    AddressIcon,
    CreditCardIcon,
    InstructionsIcon,
    InvoiceIcon,
    LogoutIcon,
    NotificationIcon,
    PasswordIcon,
    PersonalInfoIcon,
    ReferFriendIcon,
    SlidersIcon,
    HandHoldingDollarIcon,
    CircleDollarIcon,
} from '~shared/icons';
import NavMenuItem from '~shared/nav-menu-item';
import OverflowTooltip from '~shared/overflow-tooltip';
import Typography from '~shared/typography';
import {
    getSubscriberEnableDriverTips,
    isParentAccount,
} from '~store/modules/account-info/selectors';
import { logout, resetState } from '~store/modules/auth';
import { openDialog } from '~store/modules/dialog';
import { selectUserDetails } from '~store/modules/personal-info/selectors';
import { formatUserName } from '~utils/formatters';
import { PickupPlan } from '~store/modules/account-info/types';

import style from './user-menu-wrapper.style';

export interface IUserMenuWrapper {
    onDialogOpen?: () => void;
    contentStyle?: SxProps;
}

const UserMenuWrapper: React.FC<IUserMenuWrapper> = ({ onDialogOpen, contentStyle = {} }) => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const user = useAppSelector(selectUserDetails);
    const isParent = useAppSelector(isParentAccount);
    const enableDriverTips = useAppSelector(getSubscriberEnableDriverTips);

    const dispatchDialog = (type: DialogTypes) => () => {
        onDialogOpen?.();
        dispatch(openDialog(type));
    };

    const dispatchLogout = () => {
        onDialogOpen?.();
        dispatch(logout());
        dispatch(resetState());
    };

    return (
        <Stack gap={2} sx={contentStyle} data-testid='user-menu-wrapper'>
            <Stack direction='row' alignItems='center' gap={2} sx={style.personal}>
                <Avatar size={56} />
                <Box display='grid'>
                    <OverflowTooltip
                        title={formatUserName(user)}
                        placement='top-start'
                        color='#ffffff'
                        disableTouchListener
                    >
                        <Typography variant='subtitle1'>{formatUserName(user)}</Typography>
                    </OverflowTooltip>
                    <OverflowTooltip
                        title={`${user.email}`}
                        placement='bottom-start'
                        color='#ffffff'
                        disableTouchListener
                    >
                        <Typography variant='body2'>{user.email}</Typography>
                    </OverflowTooltip>
                </Box>
            </Stack>

            <Stack>
                <NavMenuItem
                    id='personalInfo'
                    onClick={dispatchDialog(DialogTypes.personalInfo)}
                    icon={PersonalInfoIcon}
                >
                    Personal Information
                </NavMenuItem>
                <NavMenuItem
                    id='laundryPreferences'
                    onClick={dispatchDialog(DialogTypes.laundryPreferences)}
                    icon={SlidersIcon}
                >
                    Laundry Preferences
                </NavMenuItem>
                {isParent && (
                    <NavMenuItem
                        id='laundryPreferences'
                        onClick={dispatchDialog(DialogTypes.specialInstructions)}
                        icon={InstructionsIcon}
                    >
                        Special Instructions
                    </NavMenuItem>
                )}
                <NavMenuItem
                    id='billingInfo'
                    onClick={dispatchDialog(DialogTypes.billingInfo)}
                    icon={CreditCardIcon}
                >
                    Billing Information
                </NavMenuItem>
                {isParent || (
                    <NavMenuItem
                        id='promoCodes'
                        onClick={dispatchDialog(DialogTypes.promoCodes)}
                        icon={CircleDollarIcon}
                    >
                        Credits
                    </NavMenuItem>
                )}
                {isParent ? (
                    <NavMenuItem
                        id='billingInfo'
                        onClick={() => navigate('/invoices', { replace: true })}
                        icon={InvoiceIcon}
                    >
                        Invoices
                    </NavMenuItem>
                ) : (
                    <NavMenuItem
                        id='invoices'
                        onClick={dispatchDialog(DialogTypes.invoices)}
                        icon={InvoiceIcon}
                    >
                        Invoices
                    </NavMenuItem>
                )}
                <NavMenuItem
                    id='addressItem'
                    onClick={dispatchDialog(DialogTypes.pickupAndDelivery)}
                    icon={AddressIcon}
                >
                    {isParent ? 'Company Address' : 'Pickup & Delivery Address'}
                </NavMenuItem>
                {!isParent && (
                    <NavMenuItem
                        id='notifications'
                        onClick={dispatchDialog(DialogTypes.notifications)}
                        icon={NotificationIcon}
                    >
                        Notifications
                    </NavMenuItem>
                )}
                {!isParent && enableDriverTips && user.planType !== PickupPlan.Commercial && (
                    <NavMenuItem
                        id='tipping'
                        onClick={dispatchDialog(DialogTypes.tippingPreferences)}
                        icon={HandHoldingDollarIcon}
                    >
                        Tipping
                    </NavMenuItem>
                )}
                {!isParent && (
                    <NavMenuItem
                        id='referFriend'
                        onClick={dispatchDialog(DialogTypes.referFriend)}
                        icon={ReferFriendIcon}
                    >
                        Refer a Friend
                    </NavMenuItem>
                )}

                <NavMenuItem
                    id='passwordUpdate'
                    onClick={dispatchDialog(DialogTypes.passwordUpdate)}
                    icon={PasswordIcon}
                >
                    Update Password
                </NavMenuItem>
                <NavMenuItem id='logout' onClick={dispatchLogout} icon={LogoutIcon}>
                    Logout
                </NavMenuItem>
            </Stack>
        </Stack>
    );
};

export default UserMenuWrapper;
