const style: Style = {
    root: {
        minHeight: '100vh',
        // appbar paddings
        pt: {
            xs: 7,
            sm: 8,
        },
        '@media (min-width:400px) and (max-width:599px)': {
            pt: 6,
        },
    },
    content: {
        position: 'absolute',
        top: '64px',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
    },
    errorMessage: {
        p: 4,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '600px',
    },
};

export default style;
