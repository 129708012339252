import { grey } from '@mui/material/colors';
import Shadows from '~constants/shadows';

const style: Style = {
    dropdown: {
        '.MuiList-root': {
            p: 0,
        },

        '.MuiPaper-root': {
            width: '100%',
            maxWidth: 300,
            pt: 0,
            boxShadow: Shadows.large,
            borderRadius: '16px',
        },

        mt: 0.5,
    },

    menuButton: {
        outline: `1px solid ${grey[300]}`,
        borderRadius: `8px`,
    },
};

export default style;
