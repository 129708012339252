import { Box } from '@mui/material';
import GooglePlaySvg from '~assets/svg/google-play.svg';

// TODO: implement
const GooglePlayLogo = () => (
    <Box height={48}>
        <GooglePlaySvg />
    </Box>
);

export default GooglePlayLogo;
