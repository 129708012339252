import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PickupPromptStatus, UpdatePickupPlan } from '~constants/schedule-pickup';
import CalendarDate from '~shared/dashboard/schedule-calendar/utils/calendar-date';
import { isUnauthorizedError } from '~utils/errors';
import { getPickupPromptStatusRequest, schedulePickupRequest } from './api';

interface ISchedulePickupState {
    pickupPromptData: Record<string, any>;
    schedulePickupRequest: Record<string, any>;
    dayClickData: Pick<CalendarDate, 'type' | 'dateInfo' | 'payload'>;
    expectedPlanType: UpdatePickupPlan;
}

const initialState: ISchedulePickupState = {
    pickupPromptData: {},
    schedulePickupRequest: {},
    dayClickData: {} as ISchedulePickupState['dayClickData'],
    expectedPlanType: UpdatePickupPlan.ByRequest,
};

export const getPickupPromptStatusAsync = createAsyncThunk(
    'schedulePickup/getPickupPromptStatusAsync',
    async ({ tempId, token, onInvalidToken }: any) => {
        try {
            const { data } = await getPickupPromptStatusRequest(token);
            return { tempId, data };
        } catch (err: any) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

export const schedulePickupAsync = createAsyncThunk(
    'schedulePickup/schedulePickupAsync',
    async ({ tempId, token, onInvalidToken }: any) => {
        try {
            await schedulePickupRequest(token);
            return tempId;
        } catch (err: any) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

const schedulePickupSlice = createSlice({
    name: 'schedulePickup',
    initialState,
    reducers: {
        setDayClickData: (state, action: PayloadAction<ISchedulePickupState['dayClickData']>) => {
            state.dayClickData = action.payload;
        },
        setPlanTypeData: (
            state,
            action: PayloadAction<ISchedulePickupState['expectedPlanType']>,
        ) => {
            state.expectedPlanType = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPickupPromptStatusAsync.fulfilled, (state, action) => {
            if (action.payload !== undefined) {
                const { tempId, data } = action.payload;
                state.pickupPromptData[tempId].data = data;
                state.pickupPromptData[tempId].isLoading = false;
            }
        });
        builder.addCase(getPickupPromptStatusAsync.pending, (state, action) => {
            const { tempId } = action.meta.arg;
            if (!state.pickupPromptData[tempId]) {
                state.pickupPromptData[tempId] = {
                    data: null,
                };
            }
            state.pickupPromptData[tempId].isLoading = true;
        });
        builder.addCase(getPickupPromptStatusAsync.rejected, (state, action) => {
            const { tempId } = action.meta.arg;
            state.pickupPromptData[tempId].isLoading = false;
            state.pickupPromptData[tempId].error = action.error;
        });
        builder.addCase(schedulePickupAsync.fulfilled, (state, action) => {
            const tempId = action.payload;
            state.schedulePickupRequest[tempId].isLoading = false;
            state.pickupPromptData[tempId].data.status = PickupPromptStatus.PickupAlreadyCreated;
        });
        builder.addCase(schedulePickupAsync.pending, (state, action) => {
            const { tempId } = action.meta.arg;
            if (!state.schedulePickupRequest[tempId]) {
                state.schedulePickupRequest[tempId] = {
                    data: null,
                };
            }
            state.schedulePickupRequest[tempId].isLoading = true;
        });
        builder.addCase(schedulePickupAsync.rejected, (state, action) => {
            const { tempId } = action.meta.arg;
            state.schedulePickupRequest[tempId].isLoading = false;
            state.schedulePickupRequest[tempId].error = action.error;
        });
    },
});

export const { setDayClickData, setPlanTypeData } = schedulePickupSlice.actions;

export default schedulePickupSlice.reducer;
