import {
    ICardForm,
    ICardValidationResult,
    IPromoCode,
    IReprocessDeclinedCardInfo,
    IReprocessDeclinedCardResult,
    IUserBillingInfoForm,
} from '~constants/billing-info';
import downloadFile from '~utils/download-file';
import client from '~utils/hn-api';

export interface IBillingHistory {
    id: number;
    invoiceDate: string;
    total: number;
    paymentStatus: number;
}

export const billingHistoryRequest = () => client.user.get<IBillingHistory[]>(`/billing/history`);

export const invoicePdfRequest = (invoiceId: number) =>
    client.user
        .get(`/billing/invoices/${invoiceId}/pdf`, { responseType: 'blob' })
        .then(downloadFile(`${invoiceId}.pdf`));

export const missedPickupsInvoicePdfRequest = (invoiceId: number) =>
    client.user
        .get(`/billing/missedPickups/${invoiceId}/pdf`, { responseType: 'blob' })
        .then(downloadFile(`${invoiceId}.pdf`));

export const billingInfoRequest = () => client.user.get('/billing');

export const updateBillingInfoRequest = (billingInfo: IUserBillingInfoForm) =>
    client.user.patch<IUserBillingInfoForm>('/billing', billingInfo);

export const validateCardRequest = (cardInfo: ICardForm) =>
    client.subscriber.post<ICardValidationResult>(`/billing/validateCard`, cardInfo);

export const reprocessDeclinedInvoicesInfoRequest = (includeMissedPickups?: boolean) =>
    client.get<IReprocessDeclinedCardInfo>(
        `/billing/reprocessDeclinedInvoices${
            includeMissedPickups ? '?includeMissedPickups=true' : ''
        }`,
    );

export const reprocessDeclinedInvoicesInfoByLinkRequest = (token: string) =>
    client.get<IReprocessDeclinedCardInfo>('/billing/reprocessDeclinedInvoices', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const reprocessDeclinedInvoicesRequest = (includeMissedPickups?: boolean) =>
    client.post<IReprocessDeclinedCardResult>(
        `/billing/reprocessDeclinedInvoices${
            includeMissedPickups ? '?includeMissedPickups=true' : ''
        }`,
    );

export const reprocessDeclinedInvoicesByLinkRequest = (token: string) =>
    client.post<IReprocessDeclinedCardResult>('/billing/reprocessDeclinedInvoices', null, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const reprocessSettleUpDeclinedInvoicesRequest = (token: string) =>
    client.post<IReprocessDeclinedCardResult>('/billing/reprocessSettleUpDeclinedInvoices', null, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const reprocessFailedPreAuthsInfoRequest = () =>
    client.get<IReprocessDeclinedCardInfo>('/billing/reprocessFailedPreAuths');

export const reprocessFailedPreAuthsInfoByLinkRequest = (token: string) =>
    client.get<IReprocessDeclinedCardInfo>('/billing/reprocessFailedPreAuths', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const reprocessFailedPreAuthsRequest = () =>
    client.post<IReprocessDeclinedCardResult>('/billing/reprocessFailedPreAuths');

export const reprocessFailedPreAuthsByLinkRequest = (token: string) =>
    client.post<IReprocessDeclinedCardResult>('/billing/reprocessFailedPreAuths', null, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
