import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastIds } from '~constants/toast-ids';
import { isBadRequestError, isNotFoundError, isUnauthorizedError } from '~utils/errors';
import { IRequestState, ITokenRequest } from '~utils/hn-api';
import { RequestStatus } from '~utils/request-state';
import { showApiErrorToast, showErrorToast, showMessageToast } from '~utils/toast-utils';
import {
    cancelCurrentPickupRequest,
    cancelPickupByDateRequest,
    getActiveManifestRequest,
    getCancelAvailable,
    getCancellationPickupInfoByDateRequest,
    getLastPickupRequest,
    getNextPickupProgressRequest,
    getPickupsByPlanRequest,
    getPickupsRequest,
    resetPickupProgressRequest,
    settleUpInfoRequest,
    settleUpRequest,
    settleUpStatusRequest,
} from './api';
import {
    CancellationPickupStatus,
    ICancellationPickupInfo,
    ICancellationPickupInfoState,
    ILastPickup,
    INextPickup,
    INextPickupProgressState,
    IPickup,
    IPickupState,
    ISettleUpInfo,
    ISettleUpInfoState,
} from './types';

const initialState = {
    pickups: {
        data: [] as IPickup[],
        status: RequestStatus.IDLE,
    } as IPickupState,

    nextPickupProgress: {
        data: {} as INextPickup,
        status: RequestStatus.IDLE,
    } as INextPickupProgressState,

    lastPickup: {
        data: {} as ILastPickup,
        isLoading: false,
        isSucceed: false,
        isError: false,
    },
    cancelAvailable: {
        isCancelAvailable: true,
        isLoading: false,
        isSucceed: false,
    },
    checkedOutManifests: {
        checkedOutManifestsByUser: false,
        isLoading: false,
        isSucceed: false,
    },
    cancelPickup: {
        isLoading: false,
        isSucceed: false,
    } as IRequestState,
    cancelSameDayPickup: {
        isLoading: false,
        isSucceed: false,
    } as IRequestState,
    cancellationPickupInfo: {
        data: {} as ICancellationPickupInfo,
        isLoading: false,
        isSucceed: false,
    } as ICancellationPickupInfoState,
    settleUpInfo: {
        data: {} as ISettleUpInfo,
        isLoading: false,
        isSucceed: false,
    } as ISettleUpInfoState,
    settleUp: {
        isLoading: false,
        isSucceed: false,
    } as IRequestState,
};

export const fetchPickupsAsync = createAsyncThunk('pickups/fetchPickupsAsync', async () => {
    const { data } = await getPickupsRequest();
    return data;
});

export const fetchNextPickupProgressAsync = createAsyncThunk(
    'pickups/fetchNextPickupProgressAsync',
    async () => {
        const { data } = await getNextPickupProgressRequest();
        return data;
    },
);

export const resetPickupProgressAsync = createAsyncThunk(
    'pickups/resetPickupProgressAsync',
    async (_, { dispatch }) => {
        await resetPickupProgressRequest();
        dispatch(fetchNextPickupProgressAsync());
    },
);

export const fetchPickupsByPlan = createAsyncThunk('pickups', async (updatePickupData: any) => {
    const { data } = await getPickupsByPlanRequest(updatePickupData);
    return data;
});

export const fetchLastPickup = createAsyncThunk('pickups/lastPickup', async () => {
    const { data } = await getLastPickupRequest();
    return data as ILastPickup;
});

export const fetchIsActiveManifest = createAsyncThunk('pickups/checkedOutManifest', async () => {
    const { data } = await getActiveManifestRequest();
    return data;
});

export const fetchCancelAvailableStatus = createAsyncThunk('pickups/cancelAvailable', async () => {
    const { data } = await getCancelAvailable();
    return data;
});

export const fetchCancellationPickupInfoByLink = createAsyncThunk(
    'notifications/cancellationInfoByLink',
    async ({ token, onInvalidToken }: ITokenRequest) => {
        try {
            const { data } = await getCancellationPickupInfoByDateRequest(token);
            return data;
        } catch (err: any) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

export const cancelPickupByLink = createAsyncThunk(
    'notifications/cancelByLink',
    async ({ token, onInvalidToken }: ITokenRequest, { dispatch }) => {
        try {
            const { data } = await cancelPickupByDateRequest(token);
            return data;
        } catch (err) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                dispatch(fetchCancellationPickupInfoByLink({ token, onInvalidToken }));
            }
        }
    },
);

export const cancelSameDayPickupAsync = createAsyncThunk(
    'pickups/cancelCurrent',
    async (toastMessage?: string) => {
        try {
            await cancelCurrentPickupRequest();
            if (toastMessage) {
                showMessageToast(toastMessage, ToastIds.SAME_DAY_CANCEL_SUCCESS);
            }
        } catch (err) {
            if (isNotFoundError(err) || isBadRequestError(err)) {
                showErrorToast(
                    "Unfortunately it's too late to cancel your pickup.",
                    ToastIds.SAME_DAY_CANCEL_REJECTED,
                );
            } else {
                showApiErrorToast(err, ToastIds.SAME_DAY_CANCEL_REJECTED);
            }
            throw err;
        }
    },
);

export const fetchSettleUpInfo = createAsyncThunk(
    'pickups/settleUpInfo',
    async ({ token, onInvalidToken }: ITokenRequest) => {
        try {
            const { data } = await settleUpInfoRequest(token);

            return data;
        } catch (err) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

export const fetchSettleUpStatus = createAsyncThunk(
    'pickups/settleUpStatus',
    async ({ token, onInvalidToken }: ITokenRequest, { getState }: any) => {
        try {
            const { pickupDate, pickupDayAsString } = getState().pickups.settleUpInfo.data;
            const { data } = await settleUpStatusRequest(token, {
                pickupDate,
                pickupDayAsString,
            });

            return data;
        } catch (err) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                throw err;
            }
        }
    },
);

export const settleUp = createAsyncThunk(
    'pickups/settleUp',
    async ({ token, onInvalidToken }: ITokenRequest, { dispatch }) => {
        try {
            const { data } = await settleUpRequest(token);
            return data;
        } catch (err) {
            if (isUnauthorizedError(err)) {
                onInvalidToken();
            } else {
                dispatch(fetchSettleUpStatus({ token, onInvalidToken }));
            }
        }
    },
);

const pickupsSlice = createSlice({
    name: 'pickups',
    initialState,
    reducers: {
        resetCancelSameDayPickupDialog: (state) => {
            state.cancelSameDayPickup.isSucceed = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPickupsAsync.pending, (state) => {
            state.pickups.status = RequestStatus.LOADING;
        });
        builder.addCase(fetchPickupsAsync.fulfilled, (state, { payload }) => {
            state.pickups.status = RequestStatus.SUCCEEDED;
            state.pickups.data = payload;
        });
        builder.addCase(fetchPickupsAsync.rejected, (state) => {
            state.pickups.status = RequestStatus.FAILED;
        });

        builder.addCase(fetchNextPickupProgressAsync.pending, (state) => {
            state.nextPickupProgress.status = RequestStatus.LOADING;
        });
        builder.addCase(fetchNextPickupProgressAsync.fulfilled, (state, { payload }) => {
            state.nextPickupProgress.status = RequestStatus.SUCCEEDED;
            state.nextPickupProgress.data = payload;
        });
        builder.addCase(fetchNextPickupProgressAsync.rejected, (state) => {
            state.nextPickupProgress.status = RequestStatus.FAILED;
        });

        builder.addCase(fetchLastPickup.pending, (state) => {
            state.lastPickup.isLoading = true;
            state.lastPickup.isSucceed = false;
        });
        builder.addCase(fetchLastPickup.fulfilled, (state, { payload }) => {
            state.lastPickup.isLoading = false;
            state.lastPickup.isSucceed = true;
            state.lastPickup.data = payload;
        });
        builder.addCase(fetchLastPickup.rejected, (state) => {
            state.lastPickup.isLoading = false;
            state.lastPickup.isSucceed = false;
        });

        builder.addCase(fetchIsActiveManifest.pending, (state) => {
            state.checkedOutManifests.isLoading = true;
            state.checkedOutManifests.isSucceed = false;
        });
        builder.addCase(fetchIsActiveManifest.fulfilled, (state, { payload }) => {
            state.checkedOutManifests.isLoading = false;
            state.checkedOutManifests.isSucceed = true;
            state.checkedOutManifests.checkedOutManifestsByUser = payload.checkedOutManifestsByUser;
        });
        builder.addCase(fetchIsActiveManifest.rejected, (state) => {
            state.checkedOutManifests.isLoading = false;
            state.checkedOutManifests.isSucceed = false;
        });

        builder.addCase(fetchCancelAvailableStatus.pending, (state) => {
            state.cancelAvailable.isLoading = true;
            state.cancelAvailable.isSucceed = false;
        });
        builder.addCase(fetchCancelAvailableStatus.fulfilled, (state, { payload }) => {
            state.cancelAvailable.isLoading = false;
            state.cancelAvailable.isSucceed = true;
            state.cancelAvailable.isCancelAvailable = payload;
        });
        builder.addCase(fetchCancelAvailableStatus.rejected, (state) => {
            state.cancelAvailable.isLoading = false;
            state.cancelAvailable.isSucceed = false;
        });

        builder.addCase(fetchCancellationPickupInfoByLink.pending, (state) => {
            state.cancellationPickupInfo.isLoading = true;
            state.cancellationPickupInfo.isSucceed = false;
        });
        builder.addCase(fetchCancellationPickupInfoByLink.fulfilled, (state, action) => {
            state.cancellationPickupInfo.isLoading = false;
            state.cancellationPickupInfo.isSucceed = true;
            if (action.payload !== undefined) {
                state.cancellationPickupInfo.data = action.payload;
            }
        });
        builder.addCase(fetchCancellationPickupInfoByLink.rejected, (state) => {
            state.cancellationPickupInfo.isLoading = false;
            state.cancellationPickupInfo.isSucceed = false;
        });

        builder.addCase(cancelPickupByLink.pending, (state) => {
            state.cancelPickup.isLoading = true;
            state.cancelPickup.isSucceed = false;
        });
        builder.addCase(cancelPickupByLink.fulfilled, (state) => {
            state.cancelPickup.isLoading = false;
            state.cancelPickup.isSucceed = true;
            state.cancellationPickupInfo.data.status = CancellationPickupStatus.Canceled;
        });
        builder.addCase(cancelPickupByLink.rejected, (state) => {
            state.cancelPickup.isLoading = false;
            state.cancelPickup.isSucceed = false;
        });

        builder.addCase(cancelSameDayPickupAsync.pending, (state) => {
            state.cancelSameDayPickup.isLoading = true;
            state.cancelSameDayPickup.isSucceed = false;
        });
        builder.addCase(cancelSameDayPickupAsync.fulfilled, (state) => {
            state.cancelSameDayPickup.isLoading = false;
            state.cancelSameDayPickup.isSucceed = true;
        });
        builder.addCase(cancelSameDayPickupAsync.rejected, (state) => {
            state.cancelSameDayPickup.isLoading = false;
            state.cancelSameDayPickup.isSucceed = false;
        });

        builder.addCase(fetchSettleUpInfo.pending, (state) => {
            state.settleUpInfo.isLoading = true;
            state.settleUpInfo.isSucceed = false;
        });
        builder.addCase(fetchSettleUpInfo.fulfilled, (state, action) => {
            state.settleUpInfo.isLoading = false;
            state.settleUpInfo.isSucceed = true;
            if (action.payload !== undefined) {
                state.settleUpInfo.data = action.payload;
            }
        });
        builder.addCase(fetchSettleUpInfo.rejected, (state) => {
            state.settleUpInfo.isLoading = false;
            state.settleUpInfo.isSucceed = false;
        });

        builder.addCase(fetchSettleUpStatus.fulfilled, (state, action) => {
            if (action.payload !== undefined) {
                state.settleUpInfo.data = {
                    ...state.settleUpInfo.data,
                    pickupStatus: action.payload,
                };
            }
        });

        builder.addCase(settleUp.pending, (state) => {
            state.settleUp.isLoading = true;
            state.settleUp.isSucceed = false;
        });
        builder.addCase(settleUp.fulfilled, (state, action) => {
            state.settleUp.isLoading = false;
            state.settleUp.isSucceed = true;
            if (action.payload !== undefined) {
                state.settleUpInfo.data = {
                    ...state.settleUpInfo.data,
                    pickupStatus: action.payload,
                };
            }
        });
        builder.addCase(settleUp.rejected, (state) => {
            state.settleUp.isLoading = false;
            state.settleUp.isSucceed = false;
        });
    },
});

export const { resetCancelSameDayPickupDialog } = pickupsSlice.actions;

export default pickupsSlice.reducer;
