import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '~store/root-reducer';
import { formatPhoneNumber } from '~utils/formatters';
import { isSucceed } from '~utils/request-state';
import { EmailHealthcheckType } from './types';

export const getFormattedSubscriberPhone = (state: RootState) =>
    state.accountInfo.subscriber.subscriberPhone
        ? formatPhoneNumber(state.accountInfo.subscriber.subscriberPhone)
        : '';

export const getSubscriberEmail = (state: RootState) =>
    state.accountInfo.subscriber.subscriberEmail;

export const getSubscriberId = (state: RootState) => state.accountInfo.subscriber.subscriberId;

export const getIsAccountInfoLoading = (state: RootState) => state.accountInfo.isAccountInfoLoading;

export const getIsAccountInfoSuccess = (state: RootState) =>
    state.accountInfo.subscriber.subscriberId !== undefined;

export const selectCustomerHold = (state: RootState) => state.accountInfo.customerHold;

export const selectVacationHold = (state: RootState) => state.accountInfo.vacationHold;

export const selectBillingHold = (state: RootState) => !state.accountInfo.isCorrectBillingInfo;

export const selectUserHold = (state: RootState) =>
    state.accountInfo.customerHold || state.accountInfo.vacationHold;

export const holdButtonLoading = (state: RootState) => state.accountInfo.vacationHoldLoading;

export const getHasEnteredPromoCode = (state: RootState) => state.accountInfo.hasEnteredPromoCode;

export const getHasDeclinedInvoices = (state: RootState) => state.accountInfo.hasDeclinedInvoices;

export const getHasFailedPreAuths = (state: RootState) => state.accountInfo.hasFailedPreAuths;

export const shouldShowSpamEmailDialog = (state: RootState) => state.accountInfo.hasFailedPreAuths;

export const getComplaintEmails = (state: RootState) =>
    state.accountInfo.emailHealthcheck?.length
        ? state.accountInfo.emailHealthcheck.filter(
              (emailHC) => !emailHC.warningSkip && emailHC.type === EmailHealthcheckType.Complaint,
          )
        : [];

export const getComplaintEmailsCount = createSelector(
    getComplaintEmails,
    (emails) => emails.length,
);

export const selectUserPickupPlanState = (state: RootState) =>
    state.accountInfo.userPickupPlanState.data;

export const selectUserPickupPlanSucceed = (state: RootState) =>
    isSucceed(state.accountInfo.userPickupPlanState.status);

export const getSignupQuery = (state: RootState) => state.accountInfo.signupQuery;

export const isParentAccount = (state: RootState) => Boolean(state.accountInfo.isParent);

export const selectUserSettings = (state: RootState) => state.accountInfo.settings;

export const getSubscriberEnableDriverTips = (state: RootState) =>
    state.accountInfo.enableDriverTips;
