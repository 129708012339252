import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useAppSelector from '~hooks/use-app-selector';
import PageLoader from '~shared/page-loader';
import { isParentAccount } from '~store/modules/account-info/selectors';

const MainPage = lazy(() => import('~components/main-page'));
const DashboardTab = lazy(() => import('~components/dashboard-tab'));
const OptInPage = lazy(() => import('~components/opt-in-page'));
const ArrivalTimePage = lazy(() => import('~components/arrival-time-page'));
const NotificationsPage = lazy(() => import('~components/notifications-page'));
const CancelPickupPage = lazy(() => import('~components/cancel-pickup-page'));
const ShortLinkMiddleware = lazy(() => import('~shared/short-link-middleware'));
const LoginAdmin = lazy(() => import('~components/login-admin'));
const ReprocessDeclinedInvoicesPage = lazy(
    () => import('~components/reprocess-declined-invoices-page'),
);
const ReprocessFailedPreAuthPage = lazy(() => import('~components/reprocess-failed-pre-auth-page'));
const Feedback = lazy(() => import('~components/feedback'));
const ReceiveEmailPage = lazy(() => import('~components/receive-email-page'));
const UnsubscribePage = lazy(() => import('~components/unsubscribe-page'));
const SettleUpPage = lazy(() => import('~components/settle-up-page'));

const ParentHomePage = lazy(() => import('~components/parent-view/home-page'));
const ParentPickupsPage = lazy(() => import('~components/parent-view/pickups-page'));
const ParentDropOffsPage = lazy(() => import('~components/parent-view/drop-offs-page'));
const ParentInvoicesPage = lazy(() => import('~components/parent-view/invoices-page'));
const ParentSettingsPage = lazy(() => import('~components/parent-view/settings-page'));

const AuthenticatedRoutes = () => {
    const isParent = useAppSelector(isParentAccount);

    const generalRoutes = (
        <>
            <Route path='/login/admin' element={<LoginAdmin />} />
            <Route path='/schedulePickup' element={<OptInPage />} />
            <Route path='/arrivalTime' element={<ArrivalTimePage />} />
            <Route path='/updateNotifications' element={<NotificationsPage />} />
            <Route path='/cancelPickup' element={<CancelPickupPage />} />
            <Route path='/declinedInvoices' element={<ReprocessDeclinedInvoicesPage />} />
            <Route path='/failedPreAuth' element={<ReprocessFailedPreAuthPage />} />
            <Route path='/settleUp' element={<SettleUpPage />} />
            <Route path='/whyReceiving' element={<ReceiveEmailPage />} />
            <Route path='/unsubscribe' element={<UnsubscribePage />} />
            <Route path='/s/:shortLink' element={<ShortLinkMiddleware />} />
            <Route path='/feedback' element={<Feedback />} />
        </>
    );

    const parentRoutes = (
        <Route path='/' element={<MainPage />}>
            <Route
                path=''
                element={
                    <Suspense fallback={<PageLoader loadingDelayMs={500} />}>
                        <ParentHomePage />
                    </Suspense>
                }
            />
            <Route
                path='all-pickups'
                element={
                    <Suspense fallback={<PageLoader loadingDelayMs={500} />}>
                        <ParentPickupsPage />
                    </Suspense>
                }
            />
            <Route
                path='all-drop-offs'
                element={
                    <Suspense fallback={<PageLoader loadingDelayMs={500} />}>
                        <ParentDropOffsPage />
                    </Suspense>
                }
            />
            <Route
                path='invoices'
                element={
                    <Suspense fallback={<PageLoader loadingDelayMs={500} />}>
                        <ParentInvoicesPage />
                    </Suspense>
                }
            />
            <Route
                path='settings'
                element={
                    <Suspense fallback={<PageLoader loadingDelayMs={500} />}>
                        <ParentSettingsPage />
                    </Suspense>
                }
            />
            <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
    );

    const userRoutes = (
        <Route path='/' element={<MainPage />}>
            <Route
                path=''
                element={
                    <Suspense fallback={<PageLoader loadingDelayMs={500} />}>
                        <DashboardTab />
                    </Suspense>
                }
            />
            <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
    );

    return (
        <Routes>
            {generalRoutes}
            {isParent ? parentRoutes : userRoutes}
        </Routes>
    );
};

export default AuthenticatedRoutes;
