import sha1 from 'sha1';
import { formatDollar } from './formatters';

export const sendImpactIdentifyInfo = (id: number, email: string) => {
    const customerId = id ? id.toString() : '';
    const customerEmail = email ? sha1(email) : '';
    try {
        // @ts-ignore use global function
        ire('identify', { customerId, customerEmail });
    } catch (e) {
        // @ts-ignore
    }
};

export const sendImpactConversionTrackingTags = (
    id: number,
    email: string,
    zip: string,
    city: string,
    promoCode?: string,
    promoCodeValue?: number,
) => {
    const customerId = id ? id.toString() : '';
    const customerEmail = email ? sha1(email) : '';
    try {
        // @ts-ignore use global function
        ire(
            'trackConversion',
            // Online Lead event; use  33716 for Sign Up
            33716,
            {
                orderId: customerId,
                customerId,
                customerEmail,
                customerPostcode: zip,
                customerCity: city,
                orderPromoCode: promoCode,
                note: promoCodeValue ? formatDollar(promoCodeValue) : undefined,
            },
            {
                verifySiteDefinitionMatch: true,
            },
        );
    } catch (e) {
        // @ts-ignore
    }
};
