import { MouseEvent, useState, FC } from 'react';
import { Box, BoxProps, Tooltip, TooltipProps } from '@mui/material';
import style from './overflow-tooltip.style';

interface IOverflowTooltip extends TooltipProps {
    boxProps?: BoxProps;
}

const OverflowTooltip: FC<IOverflowTooltip> = ({
    boxProps: { sx, ...otherBoxProps } = {},
    children,
    ...otherTooltipProps
}) => {
    const [isOverflowed, setIsOverflowed] = useState(false);

    const handleOpen = (event: MouseEvent<Element>) =>
        setIsOverflowed(event.currentTarget.scrollWidth > event.currentTarget.clientWidth);

    const handleClose = () => setIsOverflowed(false);

    return (
        <Tooltip
            arrow
            {...otherTooltipProps}
            open={isOverflowed}
            onMouseEnter={handleOpen}
            onClose={handleClose}
        >
            <Box sx={[sx, style.box] as BoxProps['sx']} {...otherBoxProps}>
                {children}
            </Box>
        </Tooltip>
    );
};

export default OverflowTooltip;
