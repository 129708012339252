import { Box, Drawer, IconButton } from '@mui/material';
import { useState } from 'react';
import { CloseIcon, MenuIcon } from '~shared/icons';
import UserMenuWrapper from '../user-menu-wrapper';
import style from './user-menu-mobile.style';

const UserMenuMobile = () => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    return (
        <Box data-testid='user-menu-mobile'>
            <Drawer
                data-testid='user-menu-mobile-drawer'
                sx={style.drawer}
                open={open}
                onClose={handleClose}
            >
                <UserMenuWrapper onDialogOpen={handleClose} contentStyle={style.menuContent} />
            </Drawer>

            <IconButton id='menu' onClick={handleOpen} data-testid='menu-icon-button'>
                {open ? (
                    <CloseIcon color='action' id='user-menu-mobile-close-icon' />
                ) : (
                    <MenuIcon color='action' id='user-menu-mobile-menu-icon' />
                )}
            </IconButton>
        </Box>
    );
};

export default UserMenuMobile;
