import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Box, IconButton, Menu } from '@mui/material';
import { useState } from 'react';
import UserMenuWrapper from '../user-menu-wrapper';
import style from './user-menu-desktop.style';

const UserMenuDesktop = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                id='avatar'
                color='action'
                disableRipple={false}
                sx={style.menuButton}
                onClick={handleClick}
            >
                <MenuRoundedIcon fontSize='medium' />
            </IconButton>

            <Menu
                sx={style.dropdown}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box pb={3}>
                    <UserMenuWrapper onDialogOpen={handleClose} />
                </Box>
            </Menu>
        </>
    );
};

export default UserMenuDesktop;
