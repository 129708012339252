import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '~utils/request-state';
import { IDropOffState } from '../types';
import { getChildrenDropOffs } from './api';

export const dropOffsAdapter = createEntityAdapter<IDropOffState>({
    selectId: (dropOff) => dropOff.userId,
});

export const fetchChildrenDropOffsAsync = createAsyncThunk(
    'childInvoices/fetchChildrenDropOffsAsync',
    async () => {
        const { data } = await getChildrenDropOffs();
        return data;
    },
);

const childrenDropOffsSlice = createSlice({
    name: 'childDropOffs',
    initialState: {
        dropOffs: dropOffsAdapter.getInitialState({
            status: RequestStatus.IDLE,
        }),
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChildrenDropOffsAsync.pending, (state) => {
                state.dropOffs.status = RequestStatus.LOADING;
            })
            .addCase(fetchChildrenDropOffsAsync.fulfilled, (state, { payload }) => {
                state.dropOffs.status = RequestStatus.SUCCEEDED;
                dropOffsAdapter.setMany(
                    state.dropOffs,
                    payload.map((dropOff) => ({
                        ...dropOff,
                        status: RequestStatus.SUCCEEDED,
                    })),
                );
            })
            .addCase(fetchChildrenDropOffsAsync.rejected, (state) => {
                state.dropOffs.status = RequestStatus.FAILED;
            });
    },
});

export default childrenDropOffsSlice.reducer;
