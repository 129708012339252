import { Theme } from '@mui/material';

const style = (theme: Theme) => ({
    toast: {
        backgroundColor: `${theme.palette.black.main}`,
        color: `${theme.palette.white.main}`,
        borderRadius: '8px',
        minHeight: 0,
    },
});

export default style;
