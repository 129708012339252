export enum ZipCodeServicedType {
    NotServiced = 1,
    ServicedByCurrentSubscriber = 2,
    ServicedByAnotherSubscriber = 3,
}

export const isZipCodeServiced = (type: ZipCodeServicedType) =>
    type === ZipCodeServicedType.ServicedByAnotherSubscriber ||
    type === ZipCodeServicedType.ServicedByCurrentSubscriber;

export const isZipCodeServicedByCurrentSubscriber = (type: ZipCodeServicedType) =>
    type === ZipCodeServicedType.ServicedByCurrentSubscriber;

export const isZipCodeServicedByAnotherSubscriber = (type: ZipCodeServicedType) =>
    type === ZipCodeServicedType.ServicedByAnotherSubscriber;

export const isZipCodeNotServiced = (type: ZipCodeServicedType) =>
    type === ZipCodeServicedType.NotServiced;
