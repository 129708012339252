import { Box } from '@mui/material';
import AppleStoreSvg from '~assets/svg/apple-store.svg';

// TODO: implement
const AppleStoreLogo = () => (
    <Box height={48}>
        <AppleStoreSvg />
    </Box>
);

export default AppleStoreLogo;
