import { UpdatePickupPlan } from '~constants/schedule-pickup';
import { ToastIds } from '~constants/toast-ids';
import { isBadRequestError, isNotFoundError } from '~utils/errors';
import client from '~utils/hn-api';
import {
    defaultErrorMessage,
    showApiErrorToast,
    showErrorToast,
    showMessageToast,
} from '~utils/toast-utils';
import { INextPickup, IPickup, ISettleUpStatusRequest } from './types';

export const getPickupsRequest = () => client.user.get<IPickup[]>('/pickups');

export const getPickupsByPlanRequest = async (updatePickupData: any) =>
    client.user.post('/pickups', updatePickupData);

export const getNextPickupProgressRequest = () => client.user.get<INextPickup>('/pickups/progress');

export const resetPickupProgressRequest = async () =>
    await client.user.post('/pickups/progress/hide');

export const getLastPickupRequest = () => client.user.get('/pickups/lastPickup');

export const getActiveManifestRequest = () => client.user.get('/pickups/checkedOutManifest');

export const getCancelAvailable = () => client.user.get('/pickups/cancelAvailable');

export const schedulePickupRequest = async (
    planType: UpdatePickupPlan,
    date: string,
    pickupDayAsString = '',
    toastMessage = '',
) => {
    try {
        const data = { scheduledPickupDate: date, pickupDayAsString, expectedPlan: planType };
        await client.user.post('/pickups/updatePickups/schedule', data);
        if (toastMessage) {
            showMessageToast(toastMessage, ToastIds.SCHEDULE_SUCCESS);
        }
    } catch (err) {
        if (isNotFoundError(err)) {
            showErrorToast(defaultErrorMessage, ToastIds.SCHEDULE_PICKUP_REJECTED);
        } else {
            showApiErrorToast(err, ToastIds.SCHEDULE_PICKUP_REJECTED);
        }
        throw err;
    }
};

export const cancelPickupRequest = async (
    planType: UpdatePickupPlan,
    date: string,
    pickupDayAsString = '',
    toastMessage = '',
) => {
    try {
        const data = { scheduledPickupDate: date, pickupDayAsString, expectedPlan: planType };

        await client.user.post('/pickups/updatePickups/canceling', data);
        if (toastMessage) {
            showMessageToast(toastMessage, ToastIds.CANCEL_SUCCESS);
        }
    } catch (err) {
        if (isNotFoundError(err) || isBadRequestError(err)) {
            showErrorToast(defaultErrorMessage, ToastIds.CANCEL_PICKUP_REJECTED);
        } else {
            showApiErrorToast(err, ToastIds.CANCEL_PICKUP_REJECTED);
        }
        throw err;
    }
};

export const cancelAllPickupsRequest = async () => {
    try {
        await client.user.post('/pickups/updatePickups/cancelAll');
    } catch (err) {
        if (isNotFoundError(err) || isBadRequestError(err)) {
            showErrorToast(defaultErrorMessage, ToastIds.CANCEL_PICKUP_REJECTED);
        } else {
            showApiErrorToast(err, ToastIds.CANCEL_PICKUP_REJECTED);
        }
        throw err;
    }
};

export const getCancellationPickupInfoByDateRequest = (token: string) =>
    client.get(`/pickups/cancel`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const cancelPickupByDateRequest = (token: string) =>
    client.post(`/pickups/cancel`, null, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const cancelCurrentPickupRequest = () => client.user.post(`/pickups/cancelCurrent`);

export const settleUpInfoRequest = (token: string) =>
    client.get(`/pickups/settleUp`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const settleUpStatusRequest = (token: string, body: ISettleUpStatusRequest) =>
    client.post(`/pickups/settleUpStatus`, body, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

export const settleUpRequest = (token: string) =>
    client.post(`/pickups/settleUp`, null, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
