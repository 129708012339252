import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HoldStatusTypes } from '~constants/hold-status-types';

interface HoldStatusState {
    holdStatusTypes: Record<HoldStatusTypes, boolean>;
}

const initialState: HoldStatusState = {
    holdStatusTypes: {
        [HoldStatusTypes.Customer]: false,
        [HoldStatusTypes.Vacation]: false,
        [HoldStatusTypes.Billing]: false,
    },
};

const holdStatusSlice = createSlice({
    name: 'holdStatus',
    initialState,
    reducers: {
        updateHoldStatusType: (
            state,
            action: PayloadAction<{ type: HoldStatusTypes; value: boolean }>,
        ) => {
            state.holdStatusTypes[action.payload.type] = action.payload.value;
        },
    },
});

export const { updateHoldStatusType } = holdStatusSlice.actions;

export default holdStatusSlice.reducer;
