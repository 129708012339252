import children from './children';
import childPickups from './pickups';
import childDropOffs from './drop-offs';
import childrenInvoices from './invoices';

const parentChild = {
    children,
    childPickups,
    childDropOffs,
    childrenInvoices,
};

export default parentChild;
