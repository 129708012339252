import { Typography as MuiTypography, TypographyProps, TypographyVariant } from '@mui/material';

export interface ITypography extends TypographyProps {
    variant?: TypographyVariant;
    color?: ColorList;
}

const Typography: React.FC<ITypography> = ({ children, color, variant, sx, ...props }) => (
    <MuiTypography {...props} variant={variant} sx={{ color: `${color}.main`, ...sx }}>
        {children}
    </MuiTypography>
);

export default Typography;
