const style: Style = {
    drawer: {
        '& .MuiDrawer-paper': {
            width: '100%',
            overflow: 'auto',
            boxSizing: 'border-box',
            pb: {
                xs: 7,
                sm: 8,
            },
            '@media (min-width:400px) and (max-width:599px)': {
                pb: 6,
            },
        },
        zIndex: 999,
    },
    menuContent: {
        // appbar paddings
        pt: {
            xs: 7,
            sm: 8,
        },
        '@media (min-width:400px) and (max-width:599px)': {
            pt: 6,
            pb: 6,
        },
    },
};

export default style;
