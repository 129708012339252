import client from '~utils/hn-api';

export const tipDriverRequest = (orderId: number, tippingInfo: any) =>
    client.user.post(`/tips/orders/${orderId}/tipDriver`, tippingInfo);

export const setTipNotificationViewedRequest = (orderId: number) =>
    client.user.patch(`/tips/orders/${orderId}/notificationViewed`);

export const getAutomaticTipAmountRequest = () => client.user.get(`/tips/auto`);

export const automaticTipAmountUpdateRequest = (autoTippingInfo: any) =>
    client.user.post(`/tips/auto`, autoTippingInfo);
