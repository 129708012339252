const style: Style = {
    root: {
        alignItems: {
            xs: 'center',
            md: 'flex-start',
        },
    },
    appleLogo: {
        mt: {
            xs: 3,
            md: 4,
        },
    },
    googleLogo: {
        mt: {
            xs: 1.5,
            md: 2,
        },
    },
};

export default style;
