import client from '~utils/hn-api';
import { IPromoCodeValidation } from './types';

export const applyPromoCodeRequest = (promoCode: string) =>
    client.user.post(`/promoCode/apply`, { promoCode });

export const fetchActivePromoCodes = () => client.user.get(`/promoCode/active`);

export const validatePromoCodeRequest = (promoCode: string) =>
    client.subscriber.get<IPromoCodeValidation>(`/promoCode/${promoCode}`);

export const fetchCreditsHistory = () => client.user.get(`/promoCode/history`);
