import { Button as MuiButton, ButtonProps } from '@mui/material';

interface IButton extends ButtonProps {
    disabled?: boolean;
}

const Button: React.FC<IButton> = ({
    onClick,
    disabled = false,
    children,
    variant = 'contained',
    color = 'action',
    ...props
}) => (
    <MuiButton color={color} variant={variant} disabled={disabled} onClick={onClick} {...props}>
        {children}
    </MuiButton>
);

export default Button;
