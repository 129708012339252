const style: Style = {
    root: {
        px: {
            lg: 10,
            md: 4,
        },
    },
};

export default style;
