import { ListItemIcon, ListItemText, MenuItem, SvgIconProps } from '@mui/material';
import React from 'react';
import Typography from '~shared/typography';
import style from './nav-menu-item.style';

interface INavMenuItem {
    id: string;
    icon: React.FC<SvgIconProps>;
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent) => void;
}

const NavMenuItem: React.FC<INavMenuItem> = ({ id, icon, children, onClick }) => {
    const Icon = icon;
    return (
        <MenuItem id={id} onClick={onClick} sx={style.root}>
            <ListItemIcon>
                <Icon color='grey1' />
            </ListItemIcon>
            <ListItemText>
                <Typography variant='body1' color='black'>
                    {children}
                </Typography>
            </ListItemText>
        </MenuItem>
    );
};

export default NavMenuItem;
