import { DateTime } from 'luxon';
import { DAY_DATE, DAY_MONTH_DATE } from '~constants/time-format';
import { IUserDetails } from '~store/modules/personal-info/types';
import { IActivePromoCode, IPromoCode } from '~store/modules/promo-codes/types';
import pluralize from 'pluralize';

export const prepareForDisplay = (source: string) => source.replace('&amp;', '&');

export const parseExpirationMonth = (expDate?: string): number =>
    expDate ? parseInt(expDate.slice(0, 2), 10) : 0;

export const parseExpirationYear = (expDate?: string): number =>
    expDate ? parseInt(expDate.slice(2), 10) : 0;

export const formatDateNotification = (
    date: Date | string,
    dateFormat = DAY_MONTH_DATE,
): string => {
    let dateTime;
    if (date instanceof Date) {
        dateTime = DateTime.fromJSDate(date);
    } else {
        dateTime = DateTime.fromSQL(date);
    }
    return dateTime.toFormat(dateFormat);
};

export const formatPhoneNumber = (phoneNumberString: string): string => {
    const match = phoneNumberString.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumberString;
};

export const formatDollar = (count: number): string =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(count);

export const formatPercentAmount = (amount: number, fractionDigits = 2) => {
    const value = amount || 0;
    return `${Number(value).toFixed(fractionDigits)}%`;
};

export const getFullName = ({ firstName, lastName }: IUserDetails) => `${firstName} ${lastName}`;

export const formatUserName = ({
    showCompanyName,
    companyName,
    firstName,
    lastName,
}: IUserDetails | any) =>
    showCompanyName ? companyName.trim() : `${firstName.trim()} ${lastName.trim()}`;

export const formatToTwoDigitNumber = (number: number): string => `0${number}`.slice(-2);

export const formatCalculationTypeLabel = (calculationType: number, capitalize = false) => {
    const label: string = calculationType === 2 ? 'bag' : 'pound';
    if (capitalize) {
        return `${label[0].toUpperCase()}${label.slice(1)}`;
    }
    return label;
};

export const formatDay = (date: string): string => DateTime.fromSQL(date).toFormat(DAY_DATE);

export const formatPromoCodeDiscount = ({ discount, isPercentDiscount }: IActivePromoCode) =>
    isPercentDiscount ? `${discount}%` : formatDollar(discount);

export const formatRemainingUses = ({
    remainingAmount,
    remainingCount,
    multiple,
    isPercentDiscount,
}: IActivePromoCode) => {
    if (multiple) {
        return `${remainingCount} ${pluralize('use', remainingCount)}`;
    }

    return isPercentDiscount ? '1 use' : formatDollar(remainingAmount);
};

export const formatPromoCodeDescription = (promoCode?: IPromoCode) => {
    if (!promoCode) {
        return '';
    }
    return promoCode.description
        ? promoCode.description
        : `$${promoCode.value} will be credited to your account.`;
};
