import styled from '@emotion/styled';
import { IconButton, useTheme } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { SNACKBAR_DELAY } from '~constants/snackbar';
import { CloseIcon } from '~shared/icons';
import getStyle from './styled-toast-container.style';

const CloseButton = ({ closeToast }) => (
    <IconButton onClick={closeToast} size='small' id='closeSnackbar'>
        <CloseIcon color='white' />
    </IconButton>
);

const StyledContainer = styled(ToastContainer)`
    &&&.Toastify__toast-container {
        min-width: fit-content;
    }
    .Toastify__toast-body {
        white-space: pre-line;
    }
`;

const StyledToastContainer: React.FC = () => {
    const style = getStyle(useTheme());

    return (
        <StyledContainer
            newestOnTop
            position='top-center'
            toastStyle={style.toast}
            hideProgressBar
            closeButton={CloseButton}
            limit={1}
            autoClose={SNACKBAR_DELAY}
        />
    );
};

export default StyledToastContainer;
