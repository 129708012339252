import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastIds } from '~constants/toast-ids';
import { IRequestState } from '~utils/hn-api';
import { showApiErrorToast } from '~utils/toast-utils';
import { fetchUserPickupPlanAsync } from '../account-info';
import { fetchUserDeliveryAddress } from '../address';
import { fetchLastDropOffForTipping } from '../drop-offs';
import {
    fetchSubscriberLaundryPreferences,
    fetchUserLaundryPreferences,
} from '../laundry-preferences';
import { fetchPickupsAsync } from '../pickups';

interface IMainInfoState {
    mainInfo: IRequestState;
}

const initialState = {
    mainInfo: {
        isLoading: false,
        isSucceed: false,
        isError: false,
    } as IRequestState,
} as IMainInfoState;

export const fetchMainInfo = createAsyncThunk('mainInfo/fetchMainInfo', async (_, { dispatch }) => {
    try {
        await Promise.all([
            dispatch(fetchPickupsAsync()),
            dispatch(fetchUserLaundryPreferences()),
            dispatch(fetchUserPickupPlanAsync()),
            dispatch(fetchSubscriberLaundryPreferences()),
            dispatch(fetchLastDropOffForTipping(true)),
            dispatch(fetchUserDeliveryAddress()),
        ]);
    } catch (err) {
        showApiErrorToast(err, ToastIds.MAIN_INFO_REJECTED);
        throw err;
    }
});

const mainInfoSlice = createSlice({
    name: 'mainInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMainInfo.pending, (state) => {
            state.mainInfo.isLoading = true;
            state.mainInfo.isSucceed = false;
            state.mainInfo.isError = false;
        });
        builder.addCase(fetchMainInfo.fulfilled, (state) => {
            state.mainInfo.isLoading = false;
            state.mainInfo.isSucceed = true;
            state.mainInfo.isError = false;
        });
        builder.addCase(fetchMainInfo.rejected, (state) => {
            state.mainInfo.isLoading = false;
            state.mainInfo.isSucceed = false;
            state.mainInfo.isError = true;
        });
    },
});

export default mainInfoSlice.reducer;
