/* eslint-disable */
import { Box, CssBaseline, Stack, Typography } from '@mui/material';
import { Component } from 'react';
import Footer from '~components/footer';
import Header from '~components/header';
import logger from '~utils/logger';
import style from './error-boundary.style';

export default class ErrorBoundary extends Component<any, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error: any) {
        return {
            hasError: true,
        };
    }

    componentDidCatch(error: any, errorInfo: any) {
        logger.error(error);
        logger.error(errorInfo);
    }

    render() {
        const { hasError } = this.state;
        if (hasError) {
            return (
                <Stack sx={style.root}>
                    <CssBaseline />
                    <Header showLogoOnly />
                    <Box sx={style.content}>
                        <Stack sx={style.errorMessage}>
                            <Box>
                                <Typography variant='h2' color='action.main' fontWeight='600'>
                                    Oops!
                                </Typography>
                                <Typography variant='h4' color='action.main' gutterBottom>
                                    Something went wrong.
                                </Typography>
                                <Typography variant='subtitle1' color='grey1.main'>
                                    We track these errors automatically but if the problem persists,
                                    please feel free to contact us.
                                </Typography>
                                <Typography variant='subtitle1' color='grey1.main'>
                                    In the meantime, please try refreshing.
                                </Typography>
                            </Box>
                        </Stack>
                        <Box>
                            <Footer showReferFriendButton={false} />
                        </Box>
                    </Box>
                </Stack>
            );
        }

        return this.props.children;
    }
}
