import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IPasswordUpdateForm, IPasswordUpdateFormState } from '~constants/password-update';
import { isBadRequestError, isNotFoundError } from '~utils/errors';
import { RequestFailed } from '~utils/hn-api';
import { ToastIds } from '~constants/toast-ids';
import {
    defaultErrorMessage,
    showApiErrorToast,
    showErrorToast,
    showMessageToast,
} from '~utils/toast-utils';
import { saveNewPasswordRequest } from './api';

const initialState = {
    data: {} as IPasswordUpdateForm,
    request: {
        isLoading: false,
        isSucceed: true,
    },
    errorMessage: '',
} as IPasswordUpdateFormState;

export const saveNewPassword = createAsyncThunk(
    'passwordUpdate/saveNewPassword',
    async (payload: IPasswordUpdateForm, { rejectWithValue }) => {
        try {
            const { data } = await saveNewPasswordRequest(payload);
            showMessageToast('Password successfully updated', ToastIds.PASSWORD_UPDATED);
            return data;
        } catch (error: any) {
            if (!isBadRequestError(error)) {
                if (isNotFoundError(error)) {
                    showErrorToast(defaultErrorMessage, ToastIds.PASSWORD_UPDATE_REJECTED);
                } else {
                    showApiErrorToast(error, ToastIds.PASSWORD_UPDATE_REJECTED);
                }
                throw error;
            }

            const axiosError: AxiosError<RequestFailed, any> = error;
            return rejectWithValue(axiosError.response?.data.message);
        }
    },
);

const passwordUpdateSlice = createSlice({
    name: 'passwordUpdate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(saveNewPassword.pending, (state) => {
            state.errorMessage = '';
            state.request = {
                isLoading: true,
                isSucceed: true,
            };
        });
        builder.addCase(saveNewPassword.rejected, (state, action) => {
            state.errorMessage = action.payload as string;
            state.request = {
                isLoading: false,
                isSucceed: false,
            };
        });
        builder.addCase(saveNewPassword.fulfilled, (state, action) => {
            state.request = {
                isLoading: false,
                isSucceed: true,
            };
            state.data = action.payload;
        });
    },
});

export default passwordUpdateSlice.reducer;
