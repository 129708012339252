import { useNavigate } from 'react-router-dom';
import useAppDispatch from '~hooks/use-app-dispatch';
import Button from '~shared/button';
import { LogoutIcon } from '~shared/icons';
import { logout, resetState } from '~store/modules/auth';
import style from './logout.style';

const Logout = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const dispatchLogout = () => {
        dispatch(logout());
        navigate('/', { replace: true });
        dispatch(resetState());
    };

    return (
        <Button variant='text' sx={style.root} onClick={dispatchLogout} id='headerCloseButton'>
            Logout
            <LogoutIcon />
        </Button>
    );
};

export default Logout;
