import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFullUrlByCode } from './api';

const initialState = {
    links: {},
};

export const getLinkByCode = createAsyncThunk('links/getLinkByCode', async (urlCode: string) => {
    const { data } = await getFullUrlByCode(urlCode);
    return { urlCode, data };
});

const linkSlice = createSlice({
    name: 'link',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLinkByCode.fulfilled, (state, action) => {
            const { urlCode, data } = action.payload;
            state.links[urlCode].data = data;
            state.links[urlCode].isLoading = false;
        });
        builder.addCase(getLinkByCode.pending, (state, action) => {
            const urlCode = action.meta.arg;
            if (!state.links[urlCode]) {
                state.links[urlCode] = {
                    data: undefined,
                };
            }
            state.links[urlCode].isLoading = true;
        });
        builder.addCase(getLinkByCode.rejected, (state, action) => {
            const urlCode = action.meta.arg;
            state.links[urlCode].data = null;
            state.links[urlCode].isLoading = false;
        });
    },
});

export default linkSlice.reducer;
