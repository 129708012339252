import { Stack } from '@mui/material';
import useBreakpoint from '~hooks/use-breakpoint';
import Link from '~shared/link';
import Typography from '~shared/typography';
import { getCurrentYear } from '~utils/dates';
import style from './footer-copyright.style';

const FooterCopyright = () => {
    const isDesktop = useBreakpoint();

    return (
        <Stack sx={style.root} justifyContent='space-between' data-testid='footerCopyright'>
            {isDesktop ? (
                <Typography id='copyrightText' color='action'>
                    &copy; {getCurrentYear()} HappyNest.
                </Typography>
            ) : (
                <Typography id='copyrightText' variant='caption' color='grey1'>
                    &copy; {getCurrentYear()} HappyNest.
                </Typography>
            )}
            <Stack direction='row' gap={1}>
                <Link id='privacyLink' href='https://www.happynest.com/privacy/' color='link'>
                    Privacy
                </Link>
                <Typography color='link' variant='body1'>
                    |
                </Typography>
                <Link
                    id='termsLink'
                    href='https://www.happynest.com/terms-of-service/'
                    color='link'
                >
                    Terms of Service
                </Link>
            </Stack>
        </Stack>
    );
};

export default FooterCopyright;
